/**
 * @description 灯塔插件工具包
 * @module types
 */

import { get } from 'lodash-es';
import type { PluginConfig, PluginVersionInfo } from '@tencent/beacon-plugin';
import { useUserStore } from '@/stores/user';

/**
 * 获取指定插件类型的插件列表
 */
function fetchPluginsByGroup({
  bizId,
  corpId,
  group,
}: {
  bizId?: string,
  corpId: string,
  group: string
}): Promise<PluginConfig[]>  {
  return window.BeaconPlugin.load(
    bizId,
    {
      corpId,
      group,
    },
  );
}

/**
 * 获取插件 JSON 配置
 * @param pluginConfig
 * @returns
 */
export function getPluginJsonConfig<T>(pluginConfig: PluginConfig): T | undefined {
  // 获取适用版本
  const version = pluginConfig?.versions
    ?.find((item: PluginVersionInfo) => item.version === pluginConfig?.version);
  // 从自定义配置中获取componentProps
  const componentPropsJSONStr = get(version, 'config', '{}');
  // 解析json字符串
  try {
    return JSON.parse(componentPropsJSONStr);
  } catch (e) {
    return;
  }
}


class LoadBeaconPlugins {
  /**
   * 全局弹窗插件列表
   */
  public fetchPlugins: Promise<PluginConfig[]> = Promise.resolve([]);

  constructor(private group: string) {
  }

  /**
   * 更新插件列表
   */
  public update(corpId: string, bizId?: string): void {
    this.fetchPlugins = fetchPluginsByGroup({
      bizId,
      corpId: corpId ?? get(window, 'AppConfig.defaultCorpId'),
      group: this.group,
    });
  }

  /**
   * 查找插件
   * @param pluginCode
   * @returns
   */
  public async find(pluginCode: string): Promise<PluginConfig|undefined> {
    let plugins = await this.fetchPlugins;
    if (!plugins) {
      window.BeaconPlugin?.init();
      this.update(useUserStore().corpId as string, undefined);
      plugins = await this.fetchPlugins;
    }
    return plugins.find((config: PluginConfig) => config.code === pluginCode);
  }
}

/**
 * 全局弹窗插件
 */
export const GlobalPopupPluginsStore = new LoadBeaconPlugins('GlobalPopup');
