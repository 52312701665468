/**
 * @description 权限
 * @module global-api
 */

import { useMicroAppStore } from '@/stores/micro-app';
import { buildFullPath, getCorpListPath, getPermissionPath } from '@/utils/dataauth-router-path';
import { BEAppKeyType } from '@/constants/micro';
import { useNavigationStore } from '@/stores/navigation';
import { NO_NEED_VERIFY_USER } from '@/constants/route';

export interface AuthParams {
  // 登陆完成的回调url
  callbackUrl: string,
}

export interface LocationParams {
  // 后端 APPKey，默认为当前 AppKey
  appKey?: BEAppKeyType;
  // 空间 ID，默认为当前空间 ID
  bizId?: string;
  // 是否直接打开页面，会调用 window.open
  open?: boolean;
}

export interface LocationRes {
  // 不带域名的完整路径，例如：location.href.replace(location.origin, '')
  fullPath: string;
  // 带域名完整路径，例如 locaiton.href
  href: string;
}

export interface AuthAPI {
  // 子应用授权登陆
  login: (params?: AuthParams) => void;
  // 登出，适用于外网
  logout: () => void;
  // 获取 DataAuth 权限设置页面路径
  getAuthSettingsPath: (params?: LocationParams) => LocationRes;
}

/**
 * 组装回调 url
 * @param url
 * @returns
 */
export const genCallBackUrl = function genCallBackUrl(url = ''): string {
  let fullCallbackUrl = url;
  if (url) {
    // 回调url是否以 / 开头
    const isRelativePath = /^\/(?!\/).*/g.test(url);
    if (isRelativePath) {
      const curAppData = useMicroAppStore();
      fullCallbackUrl = `${location.origin}/${curAppData.currentAppId}${url}`;
    }
  } else {
    fullCallbackUrl = location.href;
  }

  return fullCallbackUrl;
};

const authAPI: AuthAPI = {
  // 子应用授权登陆api
  login(params?: AuthParams): void {
    // 如果命中不鉴权路径则退出
    if (NO_NEED_VERIFY_USER.some(item => item.test(location.pathname))) return;
    const callbackUrl = genCallBackUrl(params?.callbackUrl);
    // 先decodeURIComponent，避免传过来的callbackUrl已经encodeURIComponent了一次，这边再encodeURIComponent会重复
    const redirectUrl = encodeURIComponent(decodeURIComponent(callbackUrl));
    const { loginConfig } = window.FrontConfig;
    if (loginConfig.method === 'silentLogin') {
      // IOA 登陆
      location.href = `${loginConfig.url}${redirectUrl}`;
      return;
    }
    if (loginConfig.method === 'loginPage') {
      // 登陆页登陆
      let outsideRedirectUrl = redirectUrl;
      if (/^\/console/.test(location.pathname)) {
        // 如果是在控制台 401 的，登陆时需要回到企业选择页
        outsideRedirectUrl = encodeURIComponent(buildFullPath(getCorpListPath()).href);
      }
      location.href = `${loginConfig.url}${outsideRedirectUrl}`;
    }
  },
  // 登出，适用用于 外网
  logout() {
    // 登出后再登陆回到企业选择页
    location.href = `/api/dataauth/authserver/web/logout/v2?needLoginParam=false&rawRedirectUrl=${encodeURIComponent(buildFullPath(getCorpListPath()).href)}`;
  },
  getAuthSettingsPath(params) {
    const defaultParams = {
      bizId: useNavigationStore().getCurrentBizId,
      appKey: params?.appKey || useMicroAppStore().currentAppIdBE,
    };
    Object.assign(defaultParams, params || {});
    const permissionLocation = buildFullPath(getPermissionPath(
      defaultParams.bizId,
      defaultParams.appKey,
    ));
    if (params?.open) {
      // 注意：这里直接打开相对路径即可，因为在云上版本我们嵌入在 iframe 内，需要通过代理 winodw.open 处理
      window.open(permissionLocation.fullPath);
    }
    return permissionLocation;
  },
};

export default authAPI;
