/*
 * @Author: kelvinywang
 * @Date: 2022-04-03 20:15:26
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-10-17 21:51:50
 * @Description: 路由页面
 */

import { RouteNames } from '@/constants/route';
import deviceDetect from '@/utils/device-detect';
import { MICRO_ROUTE_COMPONENT } from '@version-inject/router/microapp-routers';
import { patchRoutes } from '@version-inject/router/patch-routers';
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';
import handleBeforeEach from './before-each';

const isMobile = deviceDetect.isMobile();


/**
 * 动态生成微前端子应用的路由配置
 * 注意：window.FrontConfig 为异步获取，这里需要在 Vue 实例化时调用
 */
const generateMicroAppRoutes = function generateMicroAppRoutes(): RouteRecordRaw[] {
  const { microApps } = window.FrontConfig;
  return microApps.map((microApp: MicroApp.AppOpts) => {
    let component = MICRO_ROUTE_COMPONENT['micro-layout'];
    if (isMobile && microApp.mobile) {
      // 移动端内，并且当前应用存在移动端，使用移动端路由组件
      component = () => import('../views/mobile-layout');
    } else if (microApp.routeComponents) {
      const currentAppRouteOpts = microApp.routeComponents.find(rc => new RegExp(rc.urlMatchReg).test(location.href));
      if (currentAppRouteOpts) {
        component = MICRO_ROUTE_COMPONENT[currentAppRouteOpts.component];
      }
    }
    return {
      // 👇 非严格匹配，/${microName}/* 都指向 ${microName} 页面
      path: `/${microApp.id}/:page*`,
      name: microApp.id,
      component,
    };
  });
};

/**
 * 生成路由
 * @returns
 */
const genRoutes = function genRoutes(): Array<RouteRecordRaw> {
  return patchRoutes([
    {
      path: '/',
      name: RouteNames.HomePage,
      redirect: '/console',
    },
    {
      path: '/space-switch',
      name: RouteNames.SpaceSwitch,
      component: MICRO_ROUTE_COMPONENT['micro-layout'],
    },
    {
      path: '/space-auth',
      name: RouteNames.SpaceAuth,
      component: MICRO_ROUTE_COMPONENT['micro-layout'],
    },
    ...generateMicroAppRoutes(),
  ]);
};

let router: Router | undefined;

export default {
  initRouter() {
    router = createRouter({
      // 注意：这里 base 路径必须设置为 /
      // 为什么不设置 process.env.BASE_URL？
      // 因为这里的环境变量 BASE_URL 等于 publicPath，因此在外网构建时会变成 CDN 路径
      // 所以这里 base 路径必须设置为 /
      history: createWebHistory('/'),
      routes: genRoutes(),
    });
    router.beforeEach(handleBeforeEach);
    return router;
  },
  get instance() {
    return router;
  },
};
