/**
 * @description 性能监控埋点工具
 * @module types
 */
import type { EventLog, ReportTimeLog } from 'aegis-web-sdk/lib/packages/core/src/interface';
import { perfMAppContentFinished } from '@/services/performance/lifecycles';

export interface PerformanceAPI {
  /**
   * 子应用加载完成上报
   */
  microAppLoadFinished(appName: MicroApp.AppKeyType): void;
  /**
   * time 和 timeEnd 方法同样可用来上报自定义测速，适用于两个时间点之间时长的计算并上报
   * @param key
   */
  time(key: string): void;
  timeEnd(key: string): void;
  /**
   * 该方法可用来上报自定义测速，例如：
   * 假如‘onload’的时间是1s
   * ```
   * aegis.reportTime('onload', 1000);
   * ```
   * 或者如果需要使用额外参数，可以传入对象类型参数，ext1，ext2，ext3 会覆盖默认值：
   * ```
   * aegis.reportTime({
   *  name: 'onload', // 自定义测速 name
   *  duration: 1000, // 自定义测速耗时(0 - 60000)
   *  ext1: 'test1',
   *  ext2: 'test2',
   *  ext3: 'test3',
   * });
   * ```
   */
  reportTime(key: string | ReportTimeLog, duration?: number): void;
  /**
   * 该方法可用来上报自定义事件，系统将会自动统计上报事件的各项指标，例如：PV、平台分布等。reportEvent 可以支持字符串和对象两种类型上报参数。
   * aegis.reportEvent('XXX请求成功');
   * aegis.reportEvent({
      name: 'XXX请求成功', // 必填
      ext1: '额外参数1',
      ext2: '额外参数2',
      ext3: '额外参数3',
    })
   */
  reportEvent(event: string | EventLog): void;
}

/**
 * 校验事件名
 * @param key
 * @returns
 */
function verfiyEnventName(key: string | ReportTimeLog | EventLog): boolean {
  let eventName = '';
  if (typeof key === 'string') {
    eventName = key;
  }
  if (typeof key === 'object' && key.name) {
    eventName = key.name;
  }
  if (!eventName) {
    console.error('性能埋点 事件名不能为空');
    return false;
  }
  if (eventName.startsWith('Suite')) {
    console.error('性能埋点 事件名不能以 Suite 开头');
    return false;
  }
  return true;
}

const performanceAPI: PerformanceAPI = {
  microAppLoadFinished(appName) {
    perfMAppContentFinished(appName);
  },
  time(key: string) {
    if (!verfiyEnventName(key)) return;
    window.aegisSDK?.time(key);
    window.aegisV2?.time(key);
  },
  timeEnd(key: string) {
    if (!verfiyEnventName(key)) return;
    window.aegisSDK?.timeEnd(key);
    window.aegisV2?.timeEnd(key);
  },
  reportTime(key, duration) {
    if (!verfiyEnventName(key)) return;
    window.aegisSDK?.reportTime(key, duration);
    window.aegisV2?.reportTime(key);
  },
  reportEvent(event) {
    if (!verfiyEnventName(event)) return;
    window.aegisSDK?.reportEvent?.(event);
    window.aegisV2?.reportEvent?.(event);
  },
};

export default performanceAPI;
