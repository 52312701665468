/**
 * @description 性能埋点封装 耗时计算方法
 * @module types
 */

import { isNumber } from 'lodash-es';
import { PerfMicroAppPointEnum, PerfSuitePointEnum } from '@/services/performance/constant';

interface ITimeOptions extends PerformanceMarkOptions {
  appName: MicroApp.AppKeyType;
}

interface IBeaconPerformance {
  time(key: PerfSuitePointEnum, options?: Partial<ITimeOptions>): PerformanceMark| undefined;
  time(key: PerfMicroAppPointEnum, options: ITimeOptions): PerformanceMark| undefined;
  timeEnd(key: PerfSuitePointEnum): PerformanceMeasure | undefined;
  timeEnd(key: PerfMicroAppPointEnum, appName: MicroApp.AppKeyType): PerformanceMeasure | undefined;
}

// 是否开启计算耗时
const enableMeasure = process.env.VUE_APP_NODE_ENV === 'production' && window.AppConfig.aegisMoniterId;

/**
 * 灯塔性能埋点工具
 */
const beaconPerformance: IBeaconPerformance = {
  /**
   * time 和 timeEnd 方法用来上报自定义测速，适用于两个时间点之间时长的计算并上报
   * @param key
   */
  time(key, options?) {
    if (!enableMeasure) return;
    if (!options) {
      return performance.mark(key);
    }
    const eventName = options.appName ? `${key}/${options.appName}` : key;
    if (isNumber(options.startTime)) {
      return performance.mark(eventName, {
        startTime: options.startTime,
      });
    }
    return performance.mark(eventName);
  },
  timeEnd(key, appName?) {
    if (!enableMeasure) return;
    const eventName = appName ? `${key}/${appName}` : key;
    try {
      return performance.measure(eventName, eventName);
    } catch (e) {
      console.log('PerformanceObserver timeEnd error', e);
      return;
    }
  },
};

export default beaconPerformance;
