/**
 * @description 向 URL 后面追加参数
 * @module types
 */

interface URLParam {
  name: string,
  value: string,
  // 是否覆盖同名参数
  override?: boolean,
}

/**
 * 追加参数到 URL
 * @param path URL 地址
 * @param param 参数
 * @returns {string}
 */
export function appendParamsUrl(path: string, param: URLParam): string {
  let realPath = path;
  if (path.startsWith('//')) {
    realPath = `${location.protocol}${path}`;
  }
  try {
    const url = new URL(realPath);
    if (param.override) {
      url.searchParams.set(param.name, param.value);
    } else {
      url.searchParams.append(param.name, param.value);
    }
    return url.href;
  } catch (error) {
    console.error('appendParamsUrl', error);
  }
  return path;
}
