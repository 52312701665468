
export enum RightSideBarMenu {
  /* 用户助手 反馈中心 */
  UserHelper = 'user-helper',
  /* 性能小助手 */
  PerformanceHelper = 'performance-helper',
  /* dt GPT */
  GPTHelper = 'gpt-helper',
  /* 小程序 */
  MinPrograms = 'min-programs',
  /* 二维码 */
  QRCode = 'qr-code',
  /* 二维码 */
  BackgroundTask = 'background-task',
  /* olaChat */
  OlaChat = 'ola-chat',
}
