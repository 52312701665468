/**
 * @description 设备检测
 * @module types
 */

export const isMobile = function isMobile(): boolean {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

export default {
  isMobile,
};

