/**
 * @description 全局弹出框插槽 - 上报
 */
import BeaconSdk from '@/utils/sdk-report';

const onUserAction = (event: string, params: {[key: string]: string | number} = {}): void => {
  // 立即上报，不要使用 onUserAction
  BeaconSdk.onDirectUserAction(event, {
    ...params,
  });
};

export default {
  onUserAction,
};

