/**
 * @description 限定执行时作用域，避免被 microapp 沙盒拦截
 * @doc https://micro-zoe.github.io/micro-app/docs.html#/zh-cn/dom-scope?id=%e8%a7%a3%e9%99%a4%e5%85%83%e7%b4%a0%e7%bb%91%e5%ae%9a
 * @module types
 */
export default function limitedScope<T>(code: () => T): Promise<T> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(code());
    });
  });
}
