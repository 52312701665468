/**
 * @description 微前端 API
 * @module global-api
 */
import { cloneDeep } from 'lodash-es';
import { reload } from '@micro-zoe/micro-app';
import { AppKeyMapping, BEAppKeyType } from '@/constants/micro';
import { useMicroAppStore } from '@/stores/micro-app';
import transformAppId from '../utils/transform-app-key';

export interface MicroAPI {
  getAppConfig(anyAppKey: MicroApp.AppKeyType | BEAppKeyType): MicroApp.AppOpts | undefined;
  getAppKey(beAppKey: BEAppKeyType): MicroApp.AppKeyType;
  getBEAppKey(appKey: MicroApp.AppKeyType): BEAppKeyType;
  reloadApp(appKey: MicroApp.AppKeyType): void;
}

const microAPI: MicroAPI = {
  /**
   * 获取子应用配置信息
   * @param anyAppKey
   * @returns
   */
  getAppConfig(anyAppKey) {
    const { appKey } = transformAppId(anyAppKey);
    return cloneDeep(useMicroAppStore().getAppConfig(appKey));
  },
  /**
   * 获取前端 AppKey
   * @param scropeBeAppKey 后端 AppKey
   * @returns
   */
  getAppKey(scropeBeAppKey) {
    const { appKey } = transformAppId(scropeBeAppKey);
    return appKey;
  },
  /**
   * 获取后端 AppKey
   * @param appKey 前端 AppKey
   * @returns
   */
  getBEAppKey(appKey) {
    return AppKeyMapping[appKey];
  },
  /**
   * 自引用重载
   * @param void
   * @returns
   */
  reloadApp() {
    const { currentApp: { id: appKey } = { id: '' } } = useMicroAppStore();
    if (!appKey) return;
    reload(appKey).then((result) => {
      if (result) {
        console.log(`子应用 ${appKey} 重新渲染成功`);
      } else {
        console.log(`子应用 ${appKey} 重新渲染失败`);
      }
    });
  },
};

export default microAPI;
