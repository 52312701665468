/**
 * @description:
 * @module types
 */
import { EventMicroApp } from '@/services/events/micro-app';
import { computed, reactive, Ref, ref } from 'vue';

export enum AnnoucementPrority {
  Normal = 0,
  Middle,
  Highter
}

const BG_COLOR = {
  [AnnoucementPrority.Normal]: 'var(--beacon-color-info)',
  [AnnoucementPrority.Middle]: 'var(--beacon-color-warn)',
  [AnnoucementPrority.Highter]: 'var(--beacon-color-danger)',
};

export interface IAnnoucementConfig {
  id: string;
  content: string;
  detailUrl?: string;
  priority: AnnoucementPrority
}

// 公告信息
const annoucement: IAnnoucementConfig = reactive({
  id: '',
  content: '',
  detailUrl: '',
  priority: AnnoucementPrority.Normal,
});

const ifShow = ref(false);
const ifShowAnnoucement = computed(() => Boolean(ifShow.value && annoucement.content));

// 已提示过 localstorge 公告 ID
const LS_ANNOUCEMENT_ID = 'LS_ANNOUCEMENT_ID';


export const useAnnoucement = function useAnnoucement(): {
  ifShowAnnoucement: Ref<boolean>,
  BG_COLOR: Record<AnnoucementPrority, string>,
  annoucement: IAnnoucementConfig,
  openDetailUrl: () => void,
  openAnnoucement: (config: IAnnoucementConfig) => void,
  closeAnnoucement: () => void,
  resetAnnoucement: () => void
} {
  /**
   * 打开显示公告
   * @param config
   */
  const openAnnoucement = function openAnnoucement(config: IAnnoucementConfig): void {
    if (localStorage.getItem(LS_ANNOUCEMENT_ID) === config.id) return;
    Object.assign(annoucement, config);
    ifShow.value = true;
  };

  /**
   * 打开详情链接
   */
  const openDetailUrl = function openDetailUrl(): void {
    window.open(annoucement.detailUrl);
  };

  /**
   * 关闭公告
   */
  const closeAnnoucement = function closeAnnoucement(): void {
    ifShow.value = false;
    localStorage.setItem(LS_ANNOUCEMENT_ID, annoucement.id);
  };

  /**
   * 重置
   */
  const resetAnnoucement = function resetAnnoucement(): void {
    ifShow.value = false;
  };

  EventMicroApp.onUnmout(() => {
    // 当子应用卸载时重置状态
    resetAnnoucement();
  });

  return {
    ifShowAnnoucement,
    BG_COLOR,
    annoucement,
    openDetailUrl,
    openAnnoucement,
    closeAnnoucement,
    resetAnnoucement,
  };
};
