/**
 * @description 处理全局组件
 * @module entry
 */
import type { App } from 'vue';
import ElementPlus from 'element-plus';
import { BaseIcon } from '@suite/shared';

export const handleGlobalComponents = function handleGlobalComponents(app: App): void {
  app.use(ElementPlus, {
    namespace: 'dsjtj-el',
    // 设置基座element plus组件库z-index起始值为3000
    zIndex: 3000,
  }).component('BaseIcon', BaseIcon);
};
