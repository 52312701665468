/**
 * @description 用户反馈
 * @module types
 */
import { Component, defineAsyncComponent } from 'vue';
import limitedScope from '@/utils/limited-scope';

export function AsyncUserHelper(): Component {
  return defineAsyncComponent(() => limitedScope(() => import(
    /* webpackChunkName: "webpack-import-suite-customer-feedback" */
    './main.vue')));
}
