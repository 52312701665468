/**
 * @description 通过 svg sprint 加载 svg
 * @module types
 */
import '@version-inject/assets/svg';

export function exportSvgModules(requireContext: any): Record<string, unknown> {
  const modules: Record<string, unknown> = {};

  requireContext.keys().forEach((key: any) => {
    modules[key.replace(/(\.\/|\.svg)/g, '')] = requireContext(key);
  });
  return modules;
}

exportSvgModules(require.context('.', true));
