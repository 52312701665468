/**
 * @description microapp 框架全局生命周期处理
 * @module types
 */
import type { lifeCyclesType } from '@micro-app/types';
import { getAllApps } from '@micro-zoe/micro-app';
import { MicroAppLoadStatus } from '@/services/performance/constant';
import {
  perfMAppBeforeMounted,
  perfMAppCreated,
  perfMAppMounted,
} from '@/services/performance/lifecycles';

// 该应用是否加载过
let verifyAppCacheFlag: MicroAppLoadStatus = MicroAppLoadStatus.Initial;

/**
 * 校验应用是否加载过
 * @param appName
 * @returns
 */
const verifyAppCache = function verifyAppCache(appName: string): MicroAppLoadStatus {
  const allApps = getAllApps();
  if (!allApps.length) return MicroAppLoadStatus.Initial;
  if (allApps.includes(appName)) return MicroAppLoadStatus.Loaded;
  return MicroAppLoadStatus.First;
};

export const lifeCycles: lifeCyclesType = {
  created(e) {
    /**
     * 结束首屏预加载信息展示
     * 1：停止Intervla
     * 2：停止PerformanceObserver
     * TODO: 如果重试机制已经在准备跳转页面时（由于网速慢跳转慢），进行停止预加载将导致页面白屏
     */
    window.loadingPre?.destroy();
    const appName = e.detail.name as MicroApp.AppKeyType;
    verifyAppCacheFlag = verifyAppCache(e.detail.name);
    perfMAppCreated(verifyAppCacheFlag, appName);
    // olachat子应用加载失败的情况下不重试
    if (e.detail.name?.includes('olachat')) return;
    window.beaconLoadTimeoutStrategy?.start();
  },
  beforemount(e) {
    const appName = e.detail.name as MicroApp.AppKeyType;
    perfMAppBeforeMounted(verifyAppCacheFlag, appName);
  },
  mounted(e) {
    const appName = e.detail.name as MicroApp.AppKeyType;
    perfMAppMounted(verifyAppCacheFlag, appName);
    if (e?.detail?.name?.includes('olachat')) return;
    window.beaconLoadTimeoutStrategy?.close();
  },
  unmount() {
    // '[MICROAPP] unmount'
  },
  error() {
    // [MICROAPP] error
  },
  beforeshow() {
    // [MICROAPP] beforeshow
  },
  aftershow() {
    // [MICROAPP] aftershow
  },
  afterhidden() {
    // [MICROAPP] afterhidden
  },
};
