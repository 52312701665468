/**
 * @description 观察者，对应事件触发事会执行notify()响应
 * @module global-api
 */

import type { EventCenterObserverCallback, EventCenterObserverCallbackParams } from '../index.types';
import { getActiveApps } from '@micro-zoe/micro-app';

export const DEFAULT_APP_NAME = '$$root_event';
export default class Observer {
  // 回调函数
  private callback: EventCenterObserverCallback;
  // 当前Observer实例所属的子应用
  private appName: string;

  constructor(callback: EventCenterObserverCallback) {
    // 获取当前active的子应用
    // TODO：目前业务需求下，有且只有一个子应用active，所以直接取即可，当有多个子应用同时active时，需修改逻辑
    const [appName] = getActiveApps();
    this.callback = callback;
    this.appName = appName ?? DEFAULT_APP_NAME;
  }

  /**
   * 发送通知
   * @param args 不定参数
   */
  notify(args: EventCenterObserverCallbackParams[]): void {
    this.callback?.(...args);
  }

  /**
   * 上下文比较
   * @param context 上下文
   */
  compare(callback: EventCenterObserverCallback): boolean {
    return callback === this.callback || callback.cb === this.callback;
  }

  /**
   * @description 判断该Observer实例是否属于某个子应用
   */
  belongTo(appName: string): boolean {
    return appName === this.appName;
  }
}
