/**
 * @description 反馈分类
 * @module types
 */

import { ComputedRef, watch } from 'vue';
import { useMicroAppStore } from '@/stores/micro-app';
import { ISelectOpts } from '@/types/apis/user';
import type { UserFeedBackFormData } from '../types';

// 默认选择的反馈类型
const DEFAULT_CATEGORY_VALUE = 'other';

/**
 * 反馈分类
 */
export function useCategoriesSelect(
  formData: UserFeedBackFormData,
  categoryList: ComputedRef<ISelectOpts[]>,
): void {
  const microAppStore = useMicroAppStore();

  watch(() => [categoryList.value.length, microAppStore.comingAppId, microAppStore.currentAppId], () => {
    if (!categoryList.value.length) return;
    const currentAppKey = microAppStore.comingAppId || microAppStore.currentAppId;
    const currentCategory = categoryList.value.find(item => item.appKey === currentAppKey);
    // 设置默认选中的分类
    if (!currentCategory) {
      formData.categories = DEFAULT_CATEGORY_VALUE;
      return;
    }
    formData.categories = currentCategory.value;
  }, {
    immediate: true,
  });
}
