/**
 * @description 侧边栏 Store
 * @module store
 */

import { defineStore } from 'pinia';
import type { IconOption } from './typed';

export interface RightSidebarState {
  // 是否是黑暗模式
  isDark: boolean,
  // 启用侧边栏
  enableSideBar: boolean,
  // 是否展开
  isExpand: boolean,
  // 自定义侧边栏样式
  customSidebarStyle: {[key in string]: string},
  // 控制按钮样式
  controlBtnStyle: {[key in string]: string},
  // 侧边栏菜单配置
  menuList: IconOption[],
  // 是否右边栏在modal里
  withinModal: boolean
  // 是否触发侧边栏上升动画
  slideUpTriggered: boolean;
  toggleExpandCallBack: ((sExpand: boolean) => void) | null;
}

export const useRightSidebarStore = defineStore('rightSidebarStore', {
  state: (): RightSidebarState => ({
    isDark: false,
    isExpand: true,
    enableSideBar: false,
    menuList: [],
    customSidebarStyle: {},
    controlBtnStyle: {},
    toggleExpandCallBack: null,
    withinModal: false,
    slideUpTriggered: true,
  }),
  actions: {
    /**
     * 添加菜单
     * @param option 要添加的菜单项
     */
    setMenu(options: IconOption[]) {
      options.forEach((option) => {
        const isDuplicate = this.menuList.some(menu => menu.id === option.id);
        if (isDuplicate) {
          console.error(`添加菜单失败：ID 为 ${option.id} 的菜单项已存在`);
        } else {
          this.menuList.push(option);
        }
      });
    },
    /**
     * 删除菜单
     * @param id 要删除的菜单项的 ID
     */
    deleteMenu(ids: string | string[]): void {
      const idArray = Array.isArray(ids) ? ids : [ids];

      idArray.forEach((id) => {
        const index = this.menuList.findIndex(menu => menu.id === id);
        if (index !== -1) {
          this.menuList.splice(index, 1);
        } else {
          console.error(`删除菜单失败：未找到 ID 为 ${id} 的菜单项`);
        }
      });
    },
    /**
     * 更新引导图表
     * @param payload
     */
    updateMenu(payload: IconOption) {
      if (!payload.id) {
        console.error('更新图标未传 id');
        return;
      }
      const currentItem = this.menuList.find(item => item.id === payload.id);

      if (!currentItem) {
        console.error(`更新右侧边栏图标未找到 id：${payload.id}`);
        return;
      }
      Object.assign(currentItem, payload);
    },
    updateToggleExpandCallback(callback: ((sExpand: boolean) => void) | null): void {
      this.toggleExpandCallBack = callback;
    },
    toggleSlideUp(showAnimation = true): void {
      this.withinModal = !this.withinModal;
      if (!showAnimation) return;
      // 通过控制 slideUpTriggered 来控制 sidebar 的显隐，从而触发上划动画。
      this.slideUpTriggered = !this.slideUpTriggered;
      setTimeout(() => {
        this.slideUpTriggered = !this.slideUpTriggered;
      }, 1000);
    },
  },
});
