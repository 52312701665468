/**
 * @description 灯塔插件
 * @module types
 */

import { GlobalPopupPluginsStore } from '@/services/beacon-plugins';
import { useNavigationStore } from '@/stores/navigation';
import { useUserStore } from '@/stores/user';
import { watch } from 'vue';

export function useBeaconPlugins(): void {
  const userStore = useUserStore();
  const navigationStore = useNavigationStore();

  watch(() => [
    userStore.corpId,
    navigationStore.getCurrentBizId,
  ], ([corpId, bizId]) => {
    if (!corpId) return;
    // 当租户和空间变化后更新插件列表
    GlobalPopupPluginsStore.update(corpId, bizId || undefined);
  }, {
    immediate: true,
  });
}
