/**
 * @description loading composables
 * @module types
 */
import { ref, Ref, watch } from 'vue';
import { useCssVar } from '@vueuse/core';

type LoadingSizeType = 'big'|'normal';

const svg = `
<path fill="#528BFF" d="M20.152 3.211a4.5 4.5 0 1 0-6.364 6.364 4.5 4.5 0 1 0 6.364-6.364z"/>
<path fill="#7AA6FF" d="M30.758 13.818a4.5 4.5 0 1 0-6.364 6.364 4.5 4.5 0 1 0 6.364-6.364z"/>
<path fill="#ADC8FF" d="M20.152 24.425a4.5 4.5 0 1 0-6.364 6.364 4.5 4.5 0 1 0 6.364-6.364z"/>
<path fill="#D6E4FF" d="M9.545 13.818a4.5 4.5 0 1 0-6.364 6.364 4.5 4.5 0 1 0 6.364-6.364z"/>
`;

const el = ref(null);
const loadingSize = useCssVar('--dsjtj-el-loading-spinner-size', el);

export const useLoading = function useLoading(size: LoadingSizeType = 'normal'): {
  isLoading: Ref<boolean>,
  elLoadingConfig: Record<string, string>,
  loadingSize: Ref<string>
} {
  const isLoading = ref(false);
  const elLoadingConfig = {
    'element-loading-svg': svg,
    'element-loading-svg-view-box': '0 0 34 34',
    'element-loading-svg-width': '34px',
  };

  watch(isLoading, () => {
    if (!isLoading.value) return;
    loadingSize.value = size === 'big' ? '57px' : '34px';
  });

  return {
    isLoading,
    elLoadingConfig,
    loadingSize,
  };
};
