/**
 * @description 请求
 * @module request
 */
import { initHttp, AxiosResponse, Http } from '@suite/shared';
import { IResponse } from '@suite/shared/src/http/http';
import globalAPI from '@/services/global-api';

/**
 * 初始化基座 http
 * 独立出来方便通过 globalAPI 传给其它子应用使用，避免被修改
 * @param httpInstance
 * @returns
 */
export function initSuiteHttp(httpInstance: Http = initHttp()): Http {
  // 请求接收拦截器
  httpInstance.axiosInstance.interceptors.response.use((res: AxiosResponse<IResponse>) => res, (err) => {
    if (err.response?.status === 401) {
      globalAPI.auth.login();
    }
    // 这里必须将错误抛出，不然调用方无法 catch 错误信息
    return Promise.reject(err);
  });
  return httpInstance;
}


export default initSuiteHttp();
