// @ts-nocheck
/**
 * @description jsonp 方法，供子应用调用 jsonp 方法，该方法 copy 的 npm jsonp 忽略 lint
 * @module types
 * @see 参考 MicroApp 建议：https://zeroing.jd.com/docs.html#/zh-cn/configure?id=ignore%e5%bf%bd%e7%95%a5%e5%85%83%e7%b4%a0
 */
let count = 0;

const noop = () => {};

const jsonp = (url, opts, fn) => {
  if ('function' === typeof opts) {
    fn = opts;
    opts = {};
  }

  const prefix = opts.prefix || '__jp';
  const id = opts.name || (prefix + count);
  count += 1;
  const param = opts.param || 'callback';
  const timeout = null != opts.timeout ? opts.timeout : 60000;
  const enc = encodeURIComponent;
  const target = document.getElementsByTagName('script')[0] || document.head;
  let script;
  let timer;
  
  if (timeout) {
    timer = setTimeout(() => {
      cleanup();
      fn && fn(new Error('Timeout'));
    }, timeout);
  }

  const cleanup = () => {
    if (script.parentNode) {
      script.parentNode.removeChild(script);
    }
    window[id] = noop;
    timer && clearTimeout(timer);
  };

  const cancel = () => {
    window[id] && cleanup();
  };


  window[id] = (data) => {
    cleanup();
    fn && fn(null, data);
  };

  url += `${(~url.indexOf('?') ? '&' : '?') + param}=${enc(id)}`;
  url = url.replace('?&', '?');

  script = document.createElement('script');
  script.src = url;
  // 基座忽略拦截
  script.setAttribute('ignore', 'true');
  target.parentNode.insertBefore(script, target);

  return cancel;
};

export default jsonp;
