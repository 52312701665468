/**
 * @description 跨域 cookie 设置
 * 套件内子应用来自不同域名例如 wuji，通常无法在套件域名下设置 cookie 到 wuji 域名下
 * 这时需要隐藏一个 iframe 来请求 wuji 域名同时种下 cookie
 * @module types
 */

export function setCorsCookie(src: string): Promise<void> {
  return new Promise((reslove) => {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.style.display = 'none';
    iframe.onload = () => {
      document.body.removeChild(iframe);
      reslove();
    };
    document.body.insertBefore(iframe, document.body.firstChild);
  });
}
