/*
  @description autotrack初始化相关
  @module entry
*/
import BeaconAutoTrack from '@suite/shared/src/beacon-auto-track';
import { useUserStore } from '@/stores/user';
import { useNavigationStore } from '@/stores/navigation';
import type { OrgUint } from '@/types/apis/organisation';
import { ReleaseType, ChannelIdEnum } from '@suite/shared/src/beacon-sdk';

/**
 * 初始化 at
 * @returns
 */
async function initAutoTrack(): Promise<BeaconAutoTrack | undefined > {
  // 如果 at key 不存在直接返回，在某些版本下不需要初始化 at
  if (!window.AppConfig.atReportAppKey) return;
  const { atReportAppKey } = window.AppConfig;
  // 正式环境标志
  const isProduction = process.env.VUE_APP_NODE_ENV === 'production';
  const getAppKey = (): string => (isProduction
    ? atReportAppKey
    : 'xxxxxxx');
  const instance = new BeaconAutoTrack();
  instance.init({
    appkey: getAppKey(),
    commonParams: {
      product_name: ChannelIdEnum.Suite,
      site_type: 'domestic',
      release_version: window.AppConfig.releaseVersion as ReleaseType,
    },
    // 日志输出太多了，影响调试问题，暂时全部关闭
    consolelog: false,
  });
  return instance;
}


export const atSubscribeStore = async (): Promise<void> => {
  const instance = await initAutoTrack();
  if (!instance) return;
  useUserStore().$subscribe((mutation, state) => {
    instance.addCommonParams({
      login_name: state?.user?.userId ?? '',
      corporation_id: state?.corpId ?? '',
    });
  });
  useNavigationStore().$subscribe((mutation, state) => {
    instance.addCommonParams({
      biz_id: state.belongOrgs.find((unit: OrgUint) => unit.level === 'biz')?.id ?? '',
    });
  });
};
