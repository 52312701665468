/**
 * @description 性能埋点枚举
 * @module types
 */

/**
 * 基座相关
 */
export const enum PerfSuitePointEnum {
  /**
   * 基座首屏加载耗时
   * 页面打开 --- 基座布局完成（即子应用加载前）
   */
  FirstScreenTime='SuiteFirstScreenTime',
  /**
   * 基座首屏资源加载耗时
   * 页面打开 --- Vue 初始化
   */
  AppLoadTime = 'SuiteAppLoadTime',
  /**
   * 基座首屏布局完成耗时
   * Vue 初始化 --- 基座布局完成（即子应用加载前）
   */
  AppMounted = 'SuiteAppMounted',
  /**
   * 首屏性能指标数据
   * @doc https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/toJSON
   */
  NavigationTiming = 'SuiteNavigationTiming',
  /**
   * 捕获浏览器崩溃上报埋点
   * 常驻任务，定时检查是否存在未响应 tab
   */
  CrashDetect = 'SuiteCrashDetect',
}

/**
 * 性能埋点枚举
 */
export const enum PerfMicroAppPointEnum {
  /**
   * 基座首屏子应用 Mounted 挂载完成
   * 页面打开 --- 子应用 mounted 方法执行完成
   */
  FFSMicroAppMounted = 'SuiteFFSMicroAppMounted',
  /**
   * 基座子应用首屏耗时
   * 页面打开 ---> 子应用内上报完成
   */
  FFSMicroAppTime = 'SuiteFFSMicroAppTime',
  /**
   * 基座子应用首次打开耗时
   * 子应用 creted --- 子应用内上报完成
   */
  FMicroAppTime='SuiteFMicroAppTime',
  /**
   * 基座子应用非首次打开耗时
   * 子应用 creted --- 子应用内上报完成
   */
  MicroAppTime='SuiteMicroAppTime',

  /**
   * 基座子应用首次创建耗时
   * 子应用首次 created --- beforeMounted
   */
  FMicroAppCreated='SuiteFMicroAppCreated',

  /**
   * 基座子应用首次加载耗时
   * created --- mounted
   */
  FMicroAppLoaded='SuiteFMicroAppLoaded',

  /**
   * 基座子应用加载耗时（非首次）
   * created --- mounted
   */
  MicroAppLoaded='SuiteMicroAppLoaded',

  /**
   * 基座子应用首次挂载耗时
   * 子应用首次 beforeMounted --- mounted
   */
  FMicroAppMounted='SuiteFMicroAppMounted',

  /**
   * 基座子应用非首次创建耗时
   * 子应用非首次 created --- beforeMounted
   */
  MicroAppCreated='SuiteMicroAppCreated',

  /**
   * 基座子应用非首次挂载耗时
   * 子应用非首次 beforeMounted --- mounted
   */
  MicroAppMounted='SuiteMicroAppMounted'
}

/**
 * 子应用加载状态枚举
 */
export const enum MicroAppLoadStatus {
  /**
   * 框架初始化，页面加载
   */
  Initial,
  /**
   * 首次加载
   */
  First,
  /**
   * 已加载过
   */
  Loaded,
}
