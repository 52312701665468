/**
 * @description pinia plugin 同步插件
 * @module store
 */
import { cloneDeep } from 'lodash-es';
import { PiniaPluginContext } from 'pinia';
import eventCenter from '@/services/global-api/event/eventCenter';

/**
 * @description 创建全局通信API插件，通过获取pinia中自定义参数识别哪些store需要被全局通信API模块订阅
 */
export const globalStatePlugin = ({ store, options }: PiniaPluginContext): void => {
  if (options?.globalStore) {
    store.$subscribe((mutation, state) => {
      eventCenter.dispatch(mutation.storeId, cloneDeep(state));
    });
  }
};
