<!--
 * @Description: 加载失败时，插槽默认提供的插件
-->
<template>
  <div class="default-wrapper">
    暂无内容
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="less" scoped>
  .default-wrapper{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
