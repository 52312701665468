/**
 * @description 表单校验
 * @module types
 */
import { Ref, ref } from 'vue';
import { FormInstance, FormValidateCallback, FormValidationResult } from 'element-plus';

type IValidate = (callback?: FormValidateCallback) => FormValidationResult | undefined;

export const useFormValidate = (): {
  formRef: Ref<FormInstance | undefined>;
  validate: IValidate
} => {
  const formRef = ref<FormInstance>();

  // 支持回调函数和Promise形式
  const validate: IValidate = (callback) => {
    if (callback) {
      return formRef.value?.validate(callback);
    }
    return new Promise((resolve, reject) => {
      formRef.value?.validate((valid) => {
        if (valid) resolve(valid);
        reject(valid);
      });
    });
  };

  return {
    formRef,
    validate,
  };
};
