/**
 * @description 反馈配置
 * @module types
 */

import { useAsyncState } from '@vueuse/core';
import { Ref } from 'vue';
import { fetchUserFeedbackConfig } from '@/services/apis/upload-file';
import { IUserFeedbackConfig } from '@/types/apis/user';

export function useFeedbackOpts(): {
  feedbackOpts: Ref<IUserFeedbackConfig>
} {
  const { state: feedbackOpts } = useAsyncState(fetchUserFeedbackConfig, {
    feedbackType: [],
    categories: [],
    businessLine: [],
    channel: [],
    storyStatus: [],
  });

  return {
    feedbackOpts,
  };
}
