/**
 * @description 路由相关注入点
 * @module router
 */
import { RouteNames } from '@/constants/route';
import type { RouteRecordRaw } from 'vue-router';

/**
 * Patch 路由
 * @param defatulRoutes
 * @returns
 */
export const patchRoutes = function patchRoutes(defatulRoutes: RouteRecordRaw[]): RouteRecordRaw[] {
  const homeRouteIndex = defatulRoutes.findIndex(route => route.name === RouteNames.HomePage);
  if (homeRouteIndex === -1) return defatulRoutes;
  // 删除根页面配置
  defatulRoutes.splice(homeRouteIndex, 1);
  defatulRoutes.unshift({
    path: '/',
    name: RouteNames.HomePage,
    component: () => import('../views/home-page'),
  });
  return defatulRoutes;
};
