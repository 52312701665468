/**
 * @description MicroApp 相关事件
 * @module types
 */

import EventEmitter from 'eventemitter3';

enum EventKeys {
  Unmount = 'MicroAppUnmount'
}

const emitter = new EventEmitter();

/**
 * 发子应用卸载消息
 */
const dispatchUnmout = function dispatchUnmout(): void {
  emitter.emit(EventKeys.Unmount);
};

/**
 * 监听子应用卸载消息
 * @param onListener
 */
const onUnmout = function onUnmout(onListener: () => void): void {
  emitter.on(EventKeys.Unmount, onListener);
};

/**
 * 移除所有监听事件
 */
const removeAllListeners = function removeAllListeners(): void {
  emitter.removeAllListeners();
};

export const EventMicroApp = {
  dispatchUnmout,
  onUnmout,
  removeAllListeners,
};
