/**
 * @description 事件中心
 * @module global-api
 */
import { cloneDeep } from 'lodash-es';
import type { EventCenterObserverCallback, EventCenterObserverCallbackParams } from '../index.types';
import Observer, { DEFAULT_APP_NAME } from './observer';

class EventCenter {
  // 单例
  private static eventCenter: EventCenter;
  public static getInstance(): EventCenter {
    if (!this.eventCenter) {
      this.eventCenter = new EventCenter();
    }
    return this.eventCenter;
  }

  // 映射：事件名称 -> 回调集合
  private eventObserversMap: Map<string, Set<Observer>> = new Map();

  /**
   * 注册事件
   * @param eventName 事件名称
   * @param callback 回调函数
   */
  public on(eventName: string, callback: EventCenterObserverCallback): void {
    if (!this.eventObserversMap.has(eventName)) {
      this.eventObserversMap.set(eventName, new Set());
    }
    this.eventObserversMap.get(eventName)?.add(new Observer(callback));
  }

  /**
   * 注册一次性事件
   * @param eventName 事件名称
   * @param callback 回调函数
   */
  public once(eventName: string, callback: EventCenterObserverCallback): void {
    // eslint-disable-next-line
    const ec: EventCenter = this;
    const on = (...args: EventCenterObserverCallbackParams[]): void => {
      ec.off(eventName, on);
      callback(...args);
    };
    on.cb = callback;
    this.on(eventName, on);
  }

  /**
   * 移除事件
   * @param eventName 事件名称
   * @param callback 回调函数
   */
  public off(eventName: string, callback: EventCenterObserverCallback): void {
    // 不存在该事件
    if (!this.eventObserversMap.has(eventName)) return;
    const observers = this.eventObserversMap.get(eventName);
    // 寻找需要删除的observer
    let deletedObserver;
    observers?.forEach((observer: Observer) => {
      if (observer.compare(callback)) {
        deletedObserver = observer;
      }
    });
    if (deletedObserver) {
    //  删除对应回调函数
      observers?.delete(deletedObserver);
    }
    // 该事件下没有回调了，则删除事件名
    if (observers?.size === 0) {
      this.eventObserversMap.delete(eventName);
    }
  }

  /**
   * 发送事件
   * @param eventName 事件名称，TODO: try/catch，大耗时
   */
  public dispatch(eventName: string, ...args: EventCenterObserverCallbackParams[]): void {
    // 不存在该事件
    if (!this.eventObserversMap.has(eventName)) return;
    const observers = this.eventObserversMap.get(eventName);
    observers?.forEach((observer: Observer) => observer.notify(cloneDeep(args)));
  }

  /**
   * 清除所有回调
   * @param eventName 事件名称
   */
  public clear(eventName: string): void {
    // 不存在该事件
    if (!this.eventObserversMap.has(eventName)) return;
    this.eventObserversMap.delete(eventName);
  }

  /**
   * 清除匹配到的回调
   * @param matchReg
   */
  public clearMatch(matchReg: RegExp): void {
    this.eventObserversMap.forEach((value, key) => {
      if (matchReg.test(key)) {
        this.clear(key);
      }
    });
  }

  /**
   * @description 当子应用下线时，清除其注册的所有事件的方法
   * @param appId 子应用名称
   */
  public clearByAppName(appName?: MicroApp.AppKeyType): void {
    const innerAppName = appName ?? DEFAULT_APP_NAME;
    this.eventObserversMap.forEach((observers, eventName) => {
      observers?.forEach((observer: Observer) => {
        if (observer.belongTo(innerAppName)) {
          observers?.delete(observer);
        }
      });
      // 该事件下没有回调了，则删除事件名
      if (observers?.size === 0) {
        this.eventObserversMap.delete(eventName);
      }
    });
  }
}
const eventCenter = EventCenter.getInstance();
export default eventCenter;
