/**
 * @description 初始化 frontconfig 到 store，注意需要在 frontconfig 加载后执行
 * @module entry
 */
import { useMicroAppStore } from '@/stores/micro-app';

export const initFrontConfig = function initFrontConfig(): void {
  // 初始化 FrontConfig
  const microApp = useMicroAppStore();
  microApp.initMicroAppConfigs(window.FrontConfig.microApps);
};
