/**
 * @description 基座性能埋点生命周期
 * @module types
 */

import beaconPref from './measure';
import {
  PerfMicroAppPointEnum,
  PerfSuitePointEnum,
  MicroAppLoadStatus,
} from './constant';

/**
 * 页面打开时间点，从 0 开始
 */
const pageStartTimeOptions = {
  startTime: 0,
};

/**
 * 页面加载前埋点
 */
export function perfAppStart(appName: MicroApp.AppKeyType | undefined): void {
  if (appName) {
    beaconPref.time(PerfMicroAppPointEnum.FFSMicroAppTime, {
      appName,
      ...pageStartTimeOptions,
    });
    beaconPref.time(PerfMicroAppPointEnum.FFSMicroAppMounted, {
      appName,
      ...pageStartTimeOptions,
    });
  }
  beaconPref.time(PerfSuitePointEnum.FirstScreenTime, pageStartTimeOptions);
  beaconPref.time(PerfSuitePointEnum.AppLoadTime, pageStartTimeOptions);
}

/**
 * 应用开始初始化，即 Vue mouted
 */
export function perfAppInit(): void {
  beaconPref.timeEnd(PerfSuitePointEnum.AppLoadTime);
  beaconPref.time(PerfSuitePointEnum.AppMounted);
}

/**
 * 基座首次路由完成
 * @returns
 */
export function perfAppLayoutMounted(): void {
  beaconPref.timeEnd(PerfSuitePointEnum.AppMounted);
  beaconPref.timeEnd(PerfSuitePointEnum.FirstScreenTime);
}

/**
 * 子应用创建时
 * @param first {MicroAppLoadStatus} 是否首次
 * @param appName {MicroApp.AppKeyType}
 */
export function perfMAppCreated(first: MicroAppLoadStatus, appName: MicroApp.AppKeyType): void {
  switch (first) {
    case MicroAppLoadStatus.Initial:
    case MicroAppLoadStatus.First:
      // 首次创建
      beaconPref.time(PerfMicroAppPointEnum.FMicroAppTime, {
        appName,
      });
      beaconPref.time(PerfMicroAppPointEnum.FMicroAppLoaded, {
        appName,
      });
      beaconPref.time(PerfMicroAppPointEnum.FMicroAppCreated, {
        appName,
      });
      return;
    default:
      // 非首次创建
      beaconPref.time(PerfMicroAppPointEnum.MicroAppTime, {
        appName,
      });
      beaconPref.time(PerfMicroAppPointEnum.MicroAppLoaded, {
        appName,
      });
      beaconPref.time(PerfMicroAppPointEnum.MicroAppCreated, {
        appName,
      });
      return;
  }
}

/**
 * 子应用挂载前
 * @param first {MicroAppLoadStatus} 是否首次
 * @param appName {MicroApp.AppKeyType}
 */
export function perfMAppBeforeMounted(first: MicroAppLoadStatus, appName: MicroApp.AppKeyType): void {
  switch (first) {
    case MicroAppLoadStatus.First:
    case MicroAppLoadStatus.Initial:
      // 首次创建
      beaconPref.timeEnd(PerfMicroAppPointEnum.FMicroAppCreated, appName);
      beaconPref.time(PerfMicroAppPointEnum.FMicroAppMounted, {
        appName,
      });
      return;
    default:
      // 非首次创建
      beaconPref.timeEnd(PerfMicroAppPointEnum.MicroAppCreated, appName);
      beaconPref.time(PerfMicroAppPointEnum.MicroAppMounted, {
        appName,
      });
      return;
  }
}

/**
 * 子应用挂载后
 * @param first {MicroAppLoadStatus} 是否首次
 * @param appName {MicroApp.AppKeyType}
 */
export function perfMAppMounted(first: MicroAppLoadStatus, appName: MicroApp.AppKeyType): void {
  switch (first) {
    case MicroAppLoadStatus.Initial:
      beaconPref.timeEnd(PerfMicroAppPointEnum.FMicroAppMounted, appName);
      beaconPref.timeEnd(PerfMicroAppPointEnum.FMicroAppLoaded, appName);
      beaconPref.timeEnd(PerfMicroAppPointEnum.FFSMicroAppMounted, appName);
      return;
    case MicroAppLoadStatus.First:
      // 首次创建
      beaconPref.timeEnd(PerfMicroAppPointEnum.FMicroAppMounted, appName);
      beaconPref.timeEnd(PerfMicroAppPointEnum.FMicroAppLoaded, appName);
      return;
    default:
      // 非首次创建
      beaconPref.timeEnd(PerfMicroAppPointEnum.MicroAppMounted, appName);
      beaconPref.timeEnd(PerfMicroAppPointEnum.MicroAppLoaded, appName);
      return;
  }
}

/**
 * 子应用内容加载/渲染完成
 * @param appName {MicroApp.AppKeyType}
 * @returns
 */
export function perfMAppContentFinished(appName: MicroApp.AppKeyType): void {
  beaconPref.timeEnd(PerfMicroAppPointEnum.MicroAppTime, appName);
  beaconPref.timeEnd(PerfMicroAppPointEnum.FMicroAppTime, appName);
  beaconPref.timeEnd(PerfMicroAppPointEnum.FFSMicroAppTime, appName);
}


