/**
 * @description Store
 * @module store
 */
import { createPinia } from 'pinia';
import { globalStatePlugin } from '@/stores/plugins';

const pinia = createPinia();
pinia.use(globalStatePlugin);

export default pinia;
