/**
 * @description 全局弹出层插槽模块
 * @module global-api
 */
import type SlotGlobalToolkit from 'slot-global-toolkit';
// import type {
//   GlobalPopupSlotOpenPayload,
//   GlobalPopupSlotClosePayload,
// } from '@/components/global-popup-slot/use-global-popup-slot';
import { GlobalToolkit } from '@/components/global-toolkit';
import eventCenter from '../event/eventCenter';
import { EventCenterObserverCallback } from '../index.types';

export interface GlobalToolkitAPI {
  open(pluginCode: string,
    pluginProps: SlotGlobalToolkit.IOpenProps,
    container: HTMLElement
  ): GlobalToolkit | undefined;
  update(pluginCode: string,
    pluginProps: SlotGlobalToolkit.IOpenProps,
  ): void;
  onOpened(callback: EventCenterObserverCallback): void;
  onClosed(callback: EventCenterObserverCallback): void;
}

export enum EventType {
  // 弹窗打开事件
  OPENED = 'globalPopupSlot:opened',
  // 弹窗关闭事件
  CLOSED = 'globalPopupSlot:closed',
}

export enum GlobalPopupPluginCode {
  // [Metacat]图卡详情抽屉插件code
  METACAT_CARD_DETAIL = 'GlobalPopup_MetacatCardDetail',
  // mini 云市场弹窗插件 code
  MINI_MARKETPLACE = 'GlobalPopup_Mini_Marketplace',
  // 套餐限额弹窗 code
  SUITE_QUOTA = 'GloabalPopup_Suite_Quota',
}

const globalToolKitMap: {[key: string]: GlobalToolkit} = {};

const globalToolkitAPI: GlobalToolkitAPI = {
  /**
 * 打开全局弹窗，生成全局弹窗插件实例
 * @param pluginCode {string} 插件 code
 * @param pluginProps {Object} 插件所需参数
 */
 open(pluginCode, pluginProps, container) {
    try {
      const globalToolkitInst = new GlobalToolkit(pluginCode, pluginProps, container);
      globalToolkitInst.open();
      globalToolKitMap[pluginCode] = globalToolkitInst;
      return globalToolkitInst;
    } catch (error) {
      console.warn('初始化全局工具组件失败', error);
    }
  },
  // 异步更新payload
  update(pluginCode, payload): void {
    const currentToolKit = globalToolKitMap[pluginCode];
    if (!currentToolKit) {
      console.log('当前全局工具箱组件不存在');
      return;
    }
    currentToolKit.update(pluginCode, payload);
  },
  /**
   * @description 监听弹窗打开完成事件，该事件在弹窗关闭时会被清理
   */
  onOpened(callback: EventCenterObserverCallback): void {
    eventCenter.on(EventType.OPENED, callback);
  },
  /**
   * @description 监听弹窗完成完成事件，该事件在弹窗关闭时会被清理
   */
  onClosed(callback: EventCenterObserverCallback): void {
    eventCenter.on(EventType.CLOSED, callback);
  },
};

export default globalToolkitAPI;
