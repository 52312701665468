/**
 * @description 消息中心接口
 * @module types
 */
import http from '../request';

/**
 * 获取负责人为当前人的未读反馈数量
 * @returns
 */
export const countUnreadByOwner = function countUnreadByOwner() {
  return http.get('/api/suiteserver/web/feedback/countUnreadByOwner').then(res => res.data);
};

/**
 * 将负责人的所有反馈设置为已读
 * @returns
 */
export const readAllByOwner = function readAllByOwner() {
  return http.get('/api/suiteserver/web/feedback/readAllByOwner').then(res => res.data);
};
