/**
 * @description suite 不做 page_visit事件上报
 * @module types
 */

import { useUserStore } from '@/stores/user';
import { BeaconSDK } from '@suite/shared';
import { ChannelIdEnum } from '@suite/shared/src/beacon-sdk';
import router from '@/router';

export default BeaconSDK.init({
  channelID: ChannelIdEnum.Suite,
  debug: process.env.VUE_APP_NODE_ENV !== 'production',
  getRequiredParams: () => {
    const pageName = router?.instance?.currentRoute?.value?.name as string || '';
    return {
      product_name: ChannelIdEnum.Suite,
      login_name: useUserStore().user.userId ?? '',
      release_version: window.AppConfig.releaseVersion,
      corporation_id: useUserStore().corpId ?? '',
      page_name: pageName,
    };
  },
});
