/**
 * @description 顶部导航 API
 * @module global-api
 */
import type {
  DropDownSetting,
  EnableSpaceSwitchOpts,
  GuideOpts,
  PrimaryNavOpts,
  SpaceBottomActionOpts,
  PrimaryNavServiceButtonActionOpts,
} from '@/stores/navigation/navigation.types';
import { RecentOrgUnit, LocatedOrgReq } from '@/types/apis/organisation';
import { NavigationStoreState, useNavigationStore } from '@/stores/navigation';
import { useMicroAppStore } from '@/stores/micro-app';
import eventCenter from '../event/eventCenter';
import { EventCenterObserverCallback, NavigationAPI } from '../index.types';

enum EventType {
  // 与 StoreID 对应
  StateChange = 'navigationStore',
  // 点击一级导航
  ClickPrimaryNav = 'navigation:clickPrimaryNav',
  // 点击服务按钮
  ClickNavServiceBtn = 'navigation:ClickNavServiceBtn',
  // 点击引导
  ClickGuide = 'navigation:clickGuide',
  // 点击下拉设置
  ClickDropDownSetting = 'navigation:clickdropdownsetting',
  // 点击空间切换底部按钮
  ClickSpaceBottomAction = 'navigation:clickSpaceBottomAction',
  // 点击Logo
  ClickLogo = 'navigation:clicklogo',
  // 切换空间
  SwitchSpaceAction = 'navigation:switchSpaceAction'
}

/**
 * 状态变更事件
 * @param callback
 */
const onStateChange = function onStateChange(callback: EventCenterObserverCallback): void {
  // user需与pinia下管理的store的storeId对应
  eventCenter.on(EventType.StateChange, callback);
};

/**
 * 配置顶部导航
 * @param options {NavigationStoreState}
 * @param deepMerge {boolean}
 */
const setTopNavigation = function setTopNavigation(options: NavigationStoreState, deepMerge?: boolean): void {
  useNavigationStore().setTopNavigation(options, deepMerge);
};

/**
 * 更新引导图标配置
 * @param guide
 */
const updateGuide = function updateGuide(guide: GuideOpts): void {
  useNavigationStore().updateGuide(guide);
};

/**
 * 一级导航点击事件
 * @param callback {EventCenterObserverCallback<PrimaryNavOpts>}
 */
const onClickPrimaryNav = function onClickPrimaryNav(callback: EventCenterObserverCallback<PrimaryNavOpts>): void {
  eventCenter.on(EventType.ClickPrimaryNav, callback);
};

/**
 * 触发一级导航激活
 * @param currentNav {PrimaryNavOpts}
 */
const dispatchClickPrimaryNav = function dispatchClickPrimaryNav(currentNav: PrimaryNavOpts): void {
  eventCenter.dispatch(EventType.ClickPrimaryNav, currentNav);
};

/**
 * 监听引导被点击
 * @param callback {EventCenterObserverCallback<GuideOpts>}
 */
const onClickGuide = function onClickGuide(callback: EventCenterObserverCallback<GuideOpts>): void {
  eventCenter.on(EventType.ClickGuide, callback);
};

/**
 * 触发点击引导
 * @param currentGuide {GuideOpts}
 */
const dispatchClickGuide = function dispatchClickGuide(currentGuide: GuideOpts): void {
  eventCenter.dispatch(EventType.ClickGuide, currentGuide);
};

/**
 * 监听头像下拉功能区域被点击
 * @param callback {EventCenterObserverCallback<DropDownSetting>}
 */
const onClickDDownSetting = function onClickDDownSetting(callback: EventCenterObserverCallback<DropDownSetting>): void {
  eventCenter.on(EventType.ClickDropDownSetting, callback);
};

/**
 * 触发点击下拉设置
 * @param currentGuide {DropDownSetting}
 */
const dispatchDDownSetting = function dispatchDropDownSetting(current: DropDownSetting): void {
  eventCenter.dispatch(EventType.ClickDropDownSetting, current);
};

/**
 * 更新用户当前所在组织/空间
 * @param locatedOrgOpts {LocatedOrgReq}
 */
const updateUserCurrentOrgs = function updateUserCurrentOrgs(locatedOrgOpts: LocatedOrgReq): void {
  useNavigationStore().updateUserCurrentOrgs(Object.assign({
    appKey: useMicroAppStore().currentAppIdBE,
  }, locatedOrgOpts));
};

/**
 * 监听左上角logo被点击，如有
 * @param callback {EventCenterObserverCallback}
 */
const onClickLogo = function onClickLogo(callback: EventCenterObserverCallback): void {
  eventCenter.on(EventType.ClickLogo, callback);
  useNavigationStore().setTopNavigation({
    ifClickHeaderLogo: true,
  });
};

/**
 * 触发点击logo
 * @param currentGuide {GuideOpts}
 */
const dispatchClickLogo = function dispatchClickGuide(): void {
  eventCenter.dispatch(EventType.ClickLogo);
};

/**
 * 触发点击全部服务按钮
 */
const onClickNavServiceBtn = function onClickNavServiceBtn(callback: EventCenterObserverCallback): void {
  eventCenter.on(EventType.ClickNavServiceBtn, callback);
};

/**
 * 触发点击全部服务按钮
 */
const dispatchClickNavServiceBtn = function dispatchClickGuide(params: PrimaryNavServiceButtonActionOpts): void {
  eventCenter.dispatch(EventType.ClickNavServiceBtn, params);
};

/**
 * 更新全部服务按钮的状态
 */
const updateNavServiceBtnState = function updateNavServiceBtn(isActive: boolean): void {
  useNavigationStore().updateNavServiceBtnState(isActive);
};

/**
 * 获取当前用户组织架构信息
 * @param currentGuide {GuideOpts}
 */
const getCurrentOrganizationInfo = async function getCurrentOrganizationInfo(): Promise<RecentOrgUnit[]> {
  const { currentApp: { id: appKey } = { id: '' } } = useMicroAppStore();
  if (!appKey) {
    console.log('未找到当前激活的子应用');
    return [];
  }
  // -- to de removed 子应用空间映射完毕后，一定会分配给子应用一个默认空间，无需跳转空间选择器，无需存 sessionStorage
  // -- to de removed use-click-app.ts 文件内 onClickApp 方法在 子应用切换的时候调用。此时会将即将切换到的子应用的最近访问空间存到 sessionStorage 内，
  // -- to de removed 若 sessionStorage 内存在 recentVisitedOrgInfo 则直接返回，否则从 store 或接口获取。
  /* eslint-disable */
  // -- to de removed const recentVisitedOrgInfo = JSON.parse(sessionStorage.getItem(BEACON_RECENT_VISITED_ORG_INFO) || '[]');
  // -- to de removed if (recentVisitedOrgInfo.length > 0) {
  // -- to de removed   useNavigationStore().setBelongOrgs(recentVisitedOrgInfo, AppKeyMapping[appKey]);
  // -- to de removed   return recentVisitedOrgInfo;
  // -- to de removed }
  const navigationStore = useNavigationStore();
  const currentSpaceInfo = navigationStore.belongOrgs;
  // 当目前没有最近访问空间信息 调取接口获取
  if (currentSpaceInfo.length === 0) {
    await useNavigationStore().setRecentOrgsToBelongOrgs(appKey);
    return useNavigationStore().belongOrgs;
  }
  return currentSpaceInfo;
};

// 空间切换组件相关事件
const spaceSwitchEvent = {
  dispatchSpaceBottomAction(current: SpaceBottomActionOpts) {
    eventCenter.dispatch(EventType.ClickSpaceBottomAction, current);
  },
  onClickSpaceBottomAction(callback: EventCenterObserverCallback<SpaceBottomActionOpts>) {
    eventCenter.on(EventType.ClickSpaceBottomAction, callback);
  },
  onSwitchSpaceAction(callback: EventCenterObserverCallback<RecentOrgUnit[]>) {
    eventCenter.on(EventType.SwitchSpaceAction, callback);
  },
  dispatchSwitchSpaceAction(RecentOrgUnits: RecentOrgUnit[]): void {
    // -- to de removed 子应用空间映射完毕后，一定会分配给子应用一个默认空间，无需跳转空间选择器，无需存 sessionStorage
    // -- to de removed 空间选择器切换的时候触发个 dispatch, 此时用户需要进入选择的组织空间，
    // -- to de removed 此时需要清除掉用户子应用空间切换过来存储的最近访问组织空间信息, 避免无法跳转空间
    // -- to de removed sessionStorage.removeItem(BEACON_RECENT_VISITED_ORG_INFO);
    eventCenter.dispatch(EventType.SwitchSpaceAction, RecentOrgUnits);
  },
  // 启用空间切换组件，启用时请传入当前用户所在的组织架构信息。
  enableSpaceSwitch(spaceSwitchOpts: EnableSpaceSwitchOpts | undefined) {
    useNavigationStore().enableSpaceSwitch(spaceSwitchOpts);
    if (!spaceSwitchOpts?.userCurrentOrgs) return;
    updateUserCurrentOrgs(spaceSwitchOpts.userCurrentOrgs);
  },
  // 禁止空间切换组件
  disableSpaceSwitch() {
    useNavigationStore().disableSpaceSwitch();
  },
  // 子应用通知基座跳转到当前子应用对应的无空间申请页面
  openAppSpaceAuth() {
    useNavigationStore().openAppSpaceAuth();
  },
};

const navigationAPI: NavigationAPI = {
  onStateChange,
  setTopNavigation,
  updateGuide,
  onClickPrimaryNav,
  dispatchClickPrimaryNav,
  updateUserCurrentOrgs,
  onClickGuide,
  dispatchClickGuide,
  onClickDDownSetting,
  dispatchDDownSetting,
  onClickLogo,
  dispatchClickLogo,
  onClickNavServiceBtn,
  dispatchClickNavServiceBtn,
  updateNavServiceBtnState,
  getCurrentOrganizationInfo,
  ...spaceSwitchEvent,
};

export default navigationAPI;
