/*
 * @Author: cocogong
 * @Date: 2024-06-07 11:10:22
 * @LastEditTime: 2024-10-15 16:29:47
 * @LastEditors: cocogong
 * @Description: ola-chat
 */

import { defineStore } from 'pinia';
import type { IPosition } from './typed';

export const DISABLED_OLACHAT_TOOLBAR_KEY = {
  // 隐藏到下次访问
  DISABLED_OLACHAT_TOOLBAR_TMP: 'DISABLED_OLACHAT_TOOLBAR_TMP',
  // 仅隐藏编辑区域
  DISABLED_OLACHAT_TOOLBAR_EDITOR: 'DISABLED_OLACHAT_TOOLBAR_EDITOR',
  // 仅隐藏页面区域
  DISABLED_OLACHAT_TOOLBAR_PAGE: 'DISABLED_OLACHAT_TOOLBAR_PAGE',
  // 全局禁用
  DISABLED_OLACHAT_TOOLBAR_GLOBAL: 'DISABLED_OLACHAT_TOOLBAR_GLOBAL',
  // 快捷键唤起
  OLACHAT_TOOLBAR_AROUSE_BY_SHORTCUT: 'OLACHAT_TOOLBAR_AROUSE_BY_SHORTCUT',
  // 自动唤起
  OLACHAT_TOOLBAR_AROUSE_BY_AUTO: 'OLACHAT_TOOLBAR_AROUSE_BY_AUTO',
};

export const DISABLED_OLACHAT_TOOLBAR = '__BEACON_DISABLED_OLACHAT_TOOLBAR';

export const OLACHAT_TOOLBAR_AROUSE = '__BEACON_OLACHAT_TOOLBAR_AROUSE';
export interface SmallCopilotConfig {
  visible?: boolean,
  pos?: IPosition,
  toolTitle?: string,
  width?: number,
}

export interface StopQuestionParams {
  requestId: string,
}

export interface SqlExceptionInfo {
  sql?: string,
  dataSourceType?: number,
  dataSourceName?: string,
  dataSourceId?: string | number,
  databaseName?: string,
  tdwDatabaseOwner?: string,
  exception?: {
    title: string,
    msg: string,
    traceId: string,
  },
}
export interface QueryResultInfo {
  columns?: string[],
  columnTypes?: string[],
  rows?: any[][],
  dataResource?: {
    id?: string | number,
    fieldList: string[],
  },
}

export interface AddQuestionParams {
  content?: string,
  contextConfig: {
    userGuidOrigin: number,
    intentionType: number,
    sqlExceptionInfo?: SqlExceptionInfo,
    queryResultInfo?: QueryResultInfo,
  }
}

export interface IToolbarItem {
  id?: string,
  name?: string;
  icon?: string;
  iconSize?: number;
  onClick?: (() => void) | null;
}

export interface IChatInput {
  pos?: IPosition,
  initStr?: string,
}

export interface IToolbarConfig {
  visible?: boolean,
  pos?: IPosition,
  toolbarList: IToolbarItem[],
}

export interface IDisabledIntentionItem {
  intentionType: number,
  tipText: string,
}

export interface DataSourceInfo {
  dataSourceType?: number,
  dataSourceName?: string,
  dataSourceId?: string,
}

export interface OlaChatState {
  smallCopilotConfig: SmallCopilotConfig,
  // 提问
  copilotAddQuestionCb: ((params: AddQuestionParams) => void) | null;
  smallCopilotAddQuestionCb: ((params: AddQuestionParams) => void) | null;
  copilotInitOlaChatCb: (() => void) | null;
  showRightOlaCopilot: boolean;
  intentionText: string;
  toolbarConfigList: IToolbarItem[],
  showToolbar: boolean,
  toolbarPos: IPosition,
  toolbarConfig: IToolbarConfig,
  chatInputConfig: IChatInput,
  curTargetPos: IPosition | null,
  disabledIntentionList?: IDisabledIntentionItem[],
  dataSourceInfo?: DataSourceInfo | undefined,
  isSqlMode: boolean,
  isPageSelectionArea: boolean,
  toolbarVisibleInStorage: boolean,
  isClickRightMenuOlaEntry: boolean,
  olaChatRequestParams: any,
  olaChatSetToolsFnMap: any,
  dataResourceInfo: any,
}

export const useOlaChatStore = defineStore('olaChatStore', {
  state: (): OlaChatState => ({
    copilotAddQuestionCb: null,
    copilotInitOlaChatCb: null,
    smallCopilotConfig: {
      visible: false,
      pos: {},
      toolTitle: '',
      width: 500,
    },
    smallCopilotAddQuestionCb: null,
    showRightOlaCopilot: false,
    intentionText: '',
    toolbarConfigList: [],
    showToolbar: false,
    toolbarPos: {},
    chatInputConfig: {
      pos: {},
      initStr: '',
    },
    toolbarConfig: {
      visible: false,
      pos: {},
      toolbarList: [],
    },
    curTargetPos: null,
    disabledIntentionList: [],
    dataSourceInfo: undefined,
    isSqlMode: false,
    isPageSelectionArea: true,
    toolbarVisibleInStorage: true,
    isClickRightMenuOlaEntry: false,
    olaChatRequestParams: {},
    olaChatSetToolsFnMap: {},
    dataResourceInfo: undefined,
  }),
  actions: {
    updateCopilotAddQuestionCb(callback: (() => void) | null): void {
      this.copilotAddQuestionCb = callback;
    },
    updateSmallCopilotAddQuestionCb(callback: (() => void) | null): void {
      this.smallCopilotAddQuestionCb = callback;
    },
    updateCopilotInitOlaChatCb(callback: (() => void) | null): void {
      this.copilotInitOlaChatCb = callback;
    },
    switchSmallCopilotShow(visible?: boolean): void {
      if (visible !== undefined) {
        this.smallCopilotConfig.visible = visible;
        return;
      }
      this.smallCopilotConfig.visible = !this.smallCopilotConfig.visible;
    },
    updateSmallCopilotConfig(config: SmallCopilotConfig) {
      this.smallCopilotConfig = Object.assign({}, this.smallCopilotConfig, config);
    },
    switchRightCopilotShow(visible?: boolean, source?: string): void {
      this.isClickRightMenuOlaEntry = !!source;
      if (visible !== undefined) {
        this.showRightOlaCopilot = visible;
        return;
      }
      this.showRightOlaCopilot = !this.showRightOlaCopilot;
    },
    updateIntentionText(text: string) {
      this.intentionText = text;
    },
    updateChatInputConfig(config: IChatInput) {
      this.chatInputConfig = Object.assign({}, this.chatInputConfig, config);
    },
    updateToolbarConfig(config: IToolbarConfig) {
      this.toolbarConfig = Object.assign({}, this.toolbarConfig, config);
    },
    updateCurTargetPos(pos: IPosition | null) {
      this.curTargetPos = pos;
    },
    setDisabledIntentionList(disabledIntentionList?: IDisabledIntentionItem[]) {
      this.disabledIntentionList = disabledIntentionList;
    },
    setDataSourceInfo(dataSourceInfo?: DataSourceInfo) {
      this.dataSourceInfo = dataSourceInfo;
    },
    setDataResourceInfo(dataResourceInfo: any) {
      this.dataResourceInfo = dataResourceInfo;
    },
    setOlaChatRequestParams(params: any) {
      this.olaChatRequestParams = params;
    },
    setSqlModeStatus(status: boolean) {
      this.isSqlMode = status;
    },
    getOlaChatServerHost() {
      return window.FrontConfig.olaChatConfig?.olaMicroAppServiceHost;
    },
    updateIsPageSelectionArea(val: boolean): void {
      this.isPageSelectionArea = val;
    },
    setToolbarVisibleInStorage(visible?: boolean): void {
      if (visible !== undefined) {
        this.toolbarVisibleInStorage = visible;
        return;
      }
      const disabledOlaChatStorageVal = localStorage.getItem(DISABLED_OLACHAT_TOOLBAR);
      if (!disabledOlaChatStorageVal) return;
      if (disabledOlaChatStorageVal === DISABLED_OLACHAT_TOOLBAR_KEY.DISABLED_OLACHAT_TOOLBAR_GLOBAL) {
        this.toolbarVisibleInStorage = false;
        return;
      }
      if (
        disabledOlaChatStorageVal === DISABLED_OLACHAT_TOOLBAR_KEY.DISABLED_OLACHAT_TOOLBAR_PAGE
        && this.isPageSelectionArea
      ) {
        this.toolbarVisibleInStorage = false;
        return;
      }
      if (
        disabledOlaChatStorageVal === DISABLED_OLACHAT_TOOLBAR_KEY.DISABLED_OLACHAT_TOOLBAR_EDITOR
        && !this.isPageSelectionArea
      ) {
        this.toolbarVisibleInStorage = false;
        return;
      }
      this.toolbarVisibleInStorage = true;
    },
    updateOlaChatSetToolsFnMap(fnMap: any): void {
      this.olaChatSetToolsFnMap = Object.assign({}, this.olaChatSetToolsFnMap, fnMap || {});
    },
  },
});
