/**
 * @description 基座布局完成埋点上报
 * @module types
 */
import { onMounted } from 'vue';
import { perfAppLayoutMounted } from '@/services/performance/lifecycles';


export function useMountedReport(): void {
  onMounted(() => {
    if (!window.aegisSDK) return;
    perfAppLayoutMounted();
  });
}
