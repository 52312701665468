/**
 * @description MicroApp 高级配置（插件系统）
 * @doc: https://micro-zoe.github.io/micro-app/docs.html#/zh-cn/plugins
 * @module entry
 */
import type { AssetsChecker, prefetchParam } from '@micro-app/types';
import microApp from '@micro-zoe/micro-app';
import { lifeCycles } from './life-cycles';

// 获取需要预加载的子应用信息
const getPreloadApps = function getPreloadApps(): prefetchParam[] {
  const { microApps } = window.FrontConfig;
  const preloadApps: prefetchParam[] = [];
  microApps.every((app) => {
    const { url, preload } = app;
    if (!url || !preload) return false;
    preloadApps.push({
      name: app.id,
      url,
    });
    return true;
  });
  return preloadApps;
};

/**
 * 数组正则匹配
 * @param regStrArr
 * @param target
 * @returns
 */
const testArrayReg = function testArrayReg(
  regStrArr: string[] | undefined,
  target: string,
): boolean {
  if (!regStrArr) return false;
  return regStrArr.some(regStr => new RegExp(regStr).test(target));
};

/**
 * 获取 URL 内的主机地址
 * @param url
 * @returns
 */
const getHostByUrl = function getHostByUrl(url: string): string {
  const pattern = url.match(new RegExp(/(\w+):\/\/([^/:]+)(:\d*)?/));
  return pattern ? pattern[0] : url;
};

type MicroAppModuleConfig = Partial<Record<MicroApp.AppKeyType, {
  ignoreChecker?: AssetsChecker
}[]>>;

/**
 * 生成子应用插件配置
 * @returns
 */
const genModulesOpts = function genModulesOpts(): MicroAppModuleConfig | undefined {
  let modulesOpts: MicroAppModuleConfig | undefined = undefined;
  window.FrontConfig.microApps.forEach((app) => {
    if (app.microAppOpts) {
      modulesOpts = modulesOpts ? modulesOpts : {};
      Object.assign(modulesOpts, {
        [app.id]: [{
          ignoreChecker: (url: string) => testArrayReg(app.microAppOpts?.matchNoHanldeUrls, url),
        }],
      });
    }
  });
  return modulesOpts;
};

export const initMicroApp = function initMicroApp(): void {
  // 启动micro app
  microApp.start({
    'disable-memory-router': true, // 关闭虚拟路由系统
    'disable-patch-request': true, // 关闭对子应用请求的拦截
    preFetchApps: getPreloadApps(),
    lifeCycles,
    plugins: {
      global: [{
        scopeProperties: [
          'ElementPlus',
          'AppConfig',
          'GlobalDebugUtils',
          'FrontConfig',
          'BeaconSuiteVue',
          'BeaconSuiteElementPlus',
          'aegisSDK',
        ],
        loader: (code, url) => {
          const params: string[] = url.match(/\?.*/g) || [];
          const isIconfontAssets = params[0]?.indexOf('iconfontAssets') > -1;
          // const isIconfontUrl = url.indexOf('/iconfont.js') > -1;
          // 当url存在iconfontAssets标识，并且包含iconfont.js字符，则将svg插入到当前子应用的<micro-app-body>内，避免污染基座环境
          if (isIconfontAssets) {
            try {
              return code.replace(
                /l.parentNode.insertBefore\(a, l\);/g,
                `
              const microAppBody = document.getElementsByTagName('micro-app-body')[0];
              const parent = microAppBody.parentNode;
              const microName = parent.getAttribute('name');
              // 如果父节点的name属性和__MICRO_APP_NAME__相同即可认为是当前子应用，再将svg插入到子应用的body内
              if (window.__MICRO_APP_NAME__ === microName) {
                microAppBody.insertBefore(a, microAppBody.firstChild);
              }
            `,
              );
            } catch (err) {
              console.log('iconfont.js解析错误: ', err);
            }
          }
          return code;
        },
      }],
      modules: genModulesOpts(),
    },
    /**
     * 自定义fetch
     * @param {string} url 静态资源地址
     * @param {object} options fetch请求配置项
     * @returns Promise<string>
    */
    fetch(url, options) {
      const microAppFetchConfig = window.FrontConfig.microAppFetch;
      if (!microAppFetchConfig) return window.fetch(url, options).then(res => res.text());
      const { matchIgnoreUrls, matchCredentialsUrls, matchSimpleUrls } = microAppFetchConfig;
      if (testArrayReg(matchIgnoreUrls, url)) {
        return Promise.resolve('');
      }
      let config: Record<string, unknown> = {};
      if (testArrayReg(matchSimpleUrls, url)) {
        // 针对简单请求处理
        config = { mode: 'no-cors' };
      } else if (testArrayReg(matchCredentialsUrls, getHostByUrl(url))) {
        // 针对内网域名进行处理
        config = {
          // fetch 默认不带cookie，如果需要添加 cookie 需要配置 credentials
          // 对于子应用接入智能网关，需要将主应用下的 cookie 带上
          // 注意当 credentials 为 'include' 时，后端响应头 Access-Control-Allow-Origin 不允许为 *
          credentials: 'include',
          // 在访问不同基座地址对应同一子应用的资源情况下，
          // fetch 优先缓存的资源 Access-Control-Allow-Origin 会指向优先访问的基座域名，
          // 在另一个基座域名下时会爆跨域问题，因此这里需要设置 no-cache，
          // no-cache: 将服务器资源跟本地缓存进行比较，有新的版本才使用服务器资源，否则使用缓存。
          cache: 'no-cache',
        };
      }
      return window.fetch(url, Object.assign(options, config)).then(res => res.text());
    },
  });
};
