/**
 * @description 后端 API 集合
 * @module request
 */
import { IResponse } from '@suite/shared';
import http from '../request';
import type {
  RecentVisitData,
  RecentVisitReq,
  ReportUserVisitDataParams,
  UserInfo,
} from '@/types/apis/user';
import { PageInfo } from '@/types/apis/base';

/**
 * 上报用户最近访问的数据
 * @param params
 * @returns
 */
export const reportVisitData = function reportVisitData(params: ReportUserVisitDataParams): Promise<IResponse> {
  return http.post('/api/suiteserver/web/upload/userVisitView', params);
};

/**
 * 获取用户信息
 * @returns
 */
export const fetchUser = function fetchUser(): Promise<UserInfo> {
  return http.get('/api/suiteserver/web/user/getUserInfo').then(res => res.data);
};

/**
 * 获取最近访问数据
 * @param params
 * @returns
 */
export const fetchRecentVisitData = (params: RecentVisitReq): Promise<PageInfo<RecentVisitData[]>> => http.get('/api/suiteserver/web/recent/listAllUserView', {
  params,
}).then(res => res.data);

/**
 * 获取当前登录用户 ticket 用于到 DataAuth 换取用户 token
 * @param params
 * @returns
 */
export const fetchLoginTicket = function fetchLoginTicket(): Promise<string> {
  return http.post('/api/suiteserver/web/user/generateLoginTicket').then(res => res.data);
};
