/**
 * @description 用户反馈指引
 * @module types
 */

import { useMicroAppStore } from '@/stores/micro-app';
import { ComputedRef, computed, reactive } from 'vue';
import { mergeWith, isArray, cloneDeep } from 'lodash-es';

export const runtimeFeedbackConfig = reactive<Partial<MicroApp.IAppUserFeedBackConfig>>({});

/**
 * 用户反馈指引
 */
export function useFeedbackGuides(): {
  feedBackConfig: ComputedRef<MicroApp.IAppUserFeedBackConfig | MicroApp.IUserFeedBackConfig | undefined>,
  helperGuides: ComputedRef<MicroApp.IUserFBHelperGuide[]>,
  customGuides: ComputedRef<MicroApp.IUserFBCustomGuide[]>,
} {
  const microStore = useMicroAppStore();
  const feedBackConfig = computed<MicroApp.IAppUserFeedBackConfig | MicroApp.IUserFeedBackConfig | undefined>(() => {
    if (!microStore.ifEnableUserFeedback) return;
    const baseConfig = cloneDeep(window.FrontConfig.userFeedBack);
    if (!baseConfig) return;
    const appConfig = microStore.currentApp?.userFeedBack;
    if (!appConfig) return Object.assign(baseConfig, runtimeFeedbackConfig);
    if (appConfig.merge) return Object.assign(mergeWith(baseConfig, appConfig, (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    }), runtimeFeedbackConfig);
    return Object.assign(baseConfig, appConfig, runtimeFeedbackConfig);
  });

  const helperGuides = computed(() => feedBackConfig.value?.helperGuides || []);
  const customGuides = computed(() => (feedBackConfig.value as MicroApp.IAppUserFeedBackConfig)?.customGuide || []);

  return {
    feedBackConfig,
    helperGuides,
    customGuides,
  };
}
