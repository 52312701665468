/**
 * @description PatchAPI
 * @module global-api
 */

import { GlobalAPI } from '../index.types';

/**
 * Patch API，结合注入的 API
 * @param extendedApi
 * @returns
 */
export const patchGlobalAPI = function patchGlobalAPI<T>(
  globalAPI: GlobalAPI,
  extendedApi: T,
): GlobalAPI & T {
  return {
    ...globalAPI,
    ...extendedApi,
  };
};
