/**
 * @description 用户帮助组件
 * @module types
 */

import { runtimeFeedbackConfig } from '@/components/user-helper/composables';
import eventCenter from '../event/eventCenter';
import { EventCenterObserverCallback } from '../index.types';

export enum UserHelperEventType {
  // 点击用户指引
  CLICK_GUIDE = 'userHelper:clickGuide',
}

export interface IUserHelperAPI {
  onClickGuide(callback: EventCenterObserverCallback): void;
  setConfig(config: Partial<MicroApp.IAppUserFeedBackConfig>): void
}

const UserHelperAPI: IUserHelperAPI =  {
  onClickGuide(callback) {
    eventCenter.once(UserHelperEventType.CLICK_GUIDE, callback);
  },
  setConfig(config): void {
    Object.assign(runtimeFeedbackConfig, config);
  },
};

export default UserHelperAPI;
