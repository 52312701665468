/*
 * @Author: cocogong
 * @Date: 2024-06-03 16:58:31
 * @LastEditTime: 2024-10-15 16:29:59
 * @LastEditors: cocogong
 * @Description: olaChat模块相关的通信
 */
import {
  useOlaChatStore,
  type IToolbarConfig,
  type IChatInput,
  type SmallCopilotConfig,
  type AddQuestionParams,
  type IDisabledIntentionItem,
  type DataSourceInfo,
} from '@/stores/ola-chat';
import type {
  EventCenterObserverCallback,
} from '../index.types';
import eventCenter from '../event/eventCenter';
import { DEFAULT_RIGHT_COPILOT_WIDTH } from '@/components/ola-chat/util';

enum OlaChatEventType {
  // 与 StoreID 对应
  Action = 'olaChat:action',
}

interface OlaChatModuleParams {
  text: string,
}

export interface IOlaChatAPI {
  switchRightCopilotShowStatus(visible: boolean | undefined): void,
  addOlaChatQuestion(params: AddQuestionParams): void,
  addOlaChatSmallCopilotQuestion(params: AddQuestionParams): void,
  updateToolbarConfig(config: IToolbarConfig): void,
  updateChatInputConfig(config: IChatInput): void,
  updateSmallCopilotConfig(config: SmallCopilotConfig): void,
  addListener(cb: EventCenterObserverCallback): void,
  dispatch(type: string, data: unknown): void,
  olaChatInsertTextAtAnchor(params: OlaChatModuleParams): void,
  olaChatDataReportEvent(params: OlaChatModuleParams): void,
  olaChatIntelligentDrawEvent(params: OlaChatModuleParams): void,
  olaChatBackfilllQueryParams(params: OlaChatModuleParams): void,
  olaChatSwitchRightCopilotShowAction(visible: boolean): void,
  olaChatClickChatInputEvent(intentionType?: string): void,
  getRightCopilotCurWidth(): number,
  getRightCopilotShowStatus(): boolean,
  updateIntentionText(str: string): void,
  setDisabledIntentionList(list: IDisabledIntentionItem[]): void,
  getDisabledIntentionList(): IDisabledIntentionItem[],
  setDataSourceInfo(dataSourceInfo: DataSourceInfo): void,
  setSqlModeStatus(status: boolean): void,
  getOlaChatServerHost(): string,
  setOlaChatRequestParams(params: any): void,
  getOlaChatSetToolsFnMap(params: any): any,
  setDataResourceInfo(params: any): void,
}


// 切换右侧copilot窗口隐藏与显示
function switchRightCopilotShowStatus(visible: boolean | undefined): void {
  useOlaChatStore()?.switchRightCopilotShow?.(visible);
}

function getRightCopilotCurWidth(): number {
  return DEFAULT_RIGHT_COPILOT_WIDTH;
}

function getRightCopilotShowStatus(): boolean {
  return useOlaChatStore()?.showRightOlaCopilot;
}

function updateIntentionText(str: string): void {
  return useOlaChatStore()?.updateIntentionText?.(str);
}

function addOlaChatQuestion(params: AddQuestionParams): any {
  return useOlaChatStore()?.copilotAddQuestionCb?.(params);
}

function addOlaChatSmallCopilotQuestion(params: AddQuestionParams): any {
  return useOlaChatStore()?.smallCopilotAddQuestionCb?.(params);
}

function updateToolbarConfig(config: IToolbarConfig): void {
  if (config.visible) {
    useOlaChatStore()?.updateIsPageSelectionArea(false);
  }
  useOlaChatStore()?.updateToolbarConfig?.(config);
}

function updateChatInputConfig(config: IChatInput): void {
  useOlaChatStore()?.updateChatInputConfig?.(config);
}

function updateSmallCopilotConfig(config: SmallCopilotConfig): void {
  useOlaChatStore()?.updateSmallCopilotConfig?.(config);
}

function setDisabledIntentionList(list?: IDisabledIntentionItem[]): void {
  useOlaChatStore()?.setDisabledIntentionList?.(list);
}

function getDisabledIntentionList(): IDisabledIntentionItem[] {
  return useOlaChatStore()?.disabledIntentionList || [];
}

function setDataSourceInfo(dataSourceInfo?: DataSourceInfo): void {
  useOlaChatStore()?.setDataSourceInfo?.(dataSourceInfo);
}

function setDataResourceInfo(params: any): void {
  useOlaChatStore()?.setDataResourceInfo?.(params);
}

function setOlaChatRequestParams(params?: any): void {
  useOlaChatStore()?.setOlaChatRequestParams?.(params);
}

function setSqlModeStatus(status: boolean): void {
  useOlaChatStore()?.setSqlModeStatus?.(status);
}

function getOlaChatServerHost(): string {
  return useOlaChatStore()?.getOlaChatServerHost?.() || '';
}

function getOlaChatSetToolsFnMap(): any {
  return useOlaChatStore()?.olaChatSetToolsFnMap || {};
}

function dispatchInsertTextAtAnchor(
  type: 'insertTextAtAnchor',
  params: OlaChatModuleParams,
): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    ...params,
  });
}

function dispatchBackfillQueryParams(
  type: 'backfillQueryParams',
  params: OlaChatModuleParams,
): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    ...params,
  });
}

function dispatchRightCopilotShowStatus(
  type: 'switchRightCopilotShowStatus',
  params: boolean,
): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    params,
    isClickRightMenuOlaEntry: useOlaChatStore()?.isClickRightMenuOlaEntry,
  });
}

function dispatchClickChatInputEvent(type: 'clickChatInputEvent', params?: string): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    params,
  });
}

function dispatchDataReportEvent(type: 'olaCopilotDataReportEvent', params: OlaChatModuleParams): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    params,
  });
}

function dispatchIntelligentDrawEvent(type: 'olaCopilotIntelligentDrawEvent', params: OlaChatModuleParams): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    params,
  });
}

function olaChatInsertTextAtAnchor(params: OlaChatModuleParams): void {
  dispatchInsertTextAtAnchor('insertTextAtAnchor', params);
}

function olaChatDataReportEvent(params: OlaChatModuleParams): void {
  dispatchDataReportEvent('olaCopilotDataReportEvent', params);
}

function olaChatIntelligentDrawEvent(params: OlaChatModuleParams): void {
  dispatchIntelligentDrawEvent('olaCopilotIntelligentDrawEvent', params);
}

function olaChatSwitchRightCopilotShowAction(visible: boolean): void {
  dispatchRightCopilotShowStatus('switchRightCopilotShowStatus', visible);
}

function olaChatClickChatInputEvent(intentionType?: string): void {
  dispatchClickChatInputEvent('clickChatInputEvent', intentionType);
}

function olaChatBackfilllQueryParams(params: OlaChatModuleParams): void {
  dispatchBackfillQueryParams('backfillQueryParams', params);
}

function  addListener(cb: EventCenterObserverCallback): void {
  eventCenter.on(OlaChatEventType.Action, (...args) => {
    console.log('当前激活的 App', args);
    cb(...args);
  });
}

function dispatch(type: string, params: Record<string, unknown>): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    ...params,
  });
}

const olaChatAPI: IOlaChatAPI = {
  switchRightCopilotShowStatus,
  addOlaChatQuestion,
  addOlaChatSmallCopilotQuestion,
  updateToolbarConfig,
  updateChatInputConfig,
  updateSmallCopilotConfig,
  addListener,
  dispatch,
  olaChatInsertTextAtAnchor,
  olaChatDataReportEvent,
  olaChatSwitchRightCopilotShowAction,
  olaChatClickChatInputEvent,
  olaChatBackfilllQueryParams,
  getRightCopilotCurWidth,
  getRightCopilotShowStatus,
  updateIntentionText,
  setDisabledIntentionList,
  getDisabledIntentionList,
  setDataSourceInfo,
  setDataResourceInfo,
  setOlaChatRequestParams,
  setSqlModeStatus,
  getOlaChatServerHost,
  olaChatIntelligentDrawEvent,
  getOlaChatSetToolsFnMap,
};

export default olaChatAPI;
