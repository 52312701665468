/**
 * @description 上传文件
 * @module types
 */
import { IUserFeedbackConfig, UserFeedbackSubmit } from '@/types/apis/user';
import { IResponse } from '@suite/shared';
import http from '../request';

export function uploadFile(file: File): Promise<{
  url: string;
  name: string;
  originalName: string;
}> {
  const formData = new FormData();
  formData.append('file', file);
  return http.post(
    '/api/suiteserver/web/file/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(res => res.data);
}

export function submitUserFeedback(params: UserFeedbackSubmit): Promise<IResponse> {
  return http.post(
    '/api/suiteserver/web/feedback/submit',
    params,
  );
}

/**
 * 获取反馈配置
 */
export const fetchUserFeedbackConfig = (): Promise<IUserFeedbackConfig> => http.get('/api/suiteserver/web/feedback/config').then(res => res.data);
