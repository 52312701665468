/**
 * @description 微前端应用布局容器
 * @module types
 */
import type { RouteComponent } from 'vue-router';

// 微前端应用布局容器
export const MICRO_ROUTE_COMPONENT: Record<MicroApp.RouteComponentType, () => Promise<RouteComponent>> = {
  'micro-layout': () => import('@/views/micro-layout'),
  'blank-layout': () => import('@/views/blank-layout'),
};
