/**
 * @description 用户 Store
 * @module store
 */
import { isNil, merge } from 'lodash-es';
import { defineStore } from 'pinia';
import { fetchUser } from '@/services/apis/user';
import {
  fetchCurrentCorpId,
  fetchCurrentCorpInfo,
  fetchAppsByCorpId,
  fetchBizUserAdmin,
} from '@/services/apis/organisation';
import { buildFullPath, getCorpListPath } from '@/utils/dataauth-router-path';
import type { UserInfo } from '@/types/apis/user';
import type { AppInfo, CorporationInfo, UserOrgAdmin } from '@/types/apis/organisation';
import type { BEAppKeyType } from '@/constants/micro';

export interface UserStoreState {
  // 当前用户信息
  user: UserInfo;
  // 当前租户 ID
  corpId: string | null;
  // 当前租户信息
  corp: CorporationInfo;
  // 当前租户下产品信息
  apps: AppInfo[];
  // 用户在当前空间下的管理员情况，当空间切换时同步更新该数据
  orgAdmin: UserOrgAdmin;
}

// localstorge 存储的租户 ID
const LS_CORP_ID_KEY = '__BEACON_CORP_ID__';

const getDefault = (): UserOrgAdmin => ({
  busiAdmin: false,
  bizAdmin: false,
  bizGlobalAdmin: false,
});

export const useUserStore = defineStore('userStore', {
  state: (): UserStoreState => ({
    user: {
      userName: '',
      avatar: '',
      email: '',
      isIoaUser: '',
      relatedOaUserId: '',
      userId: '',
      areaCode: '',
      phone: '',
    },
    // 默认租户 ID 不要设置默认值
    // 在页面推送场景下，会由于有默认 ID 存在导致上报用户访问接口被调起返回 401 导致推送失败
    corpId: null,
    corp: {
      allowEmailSuffixes: '',
      appKeys: [],
      contact: '',
      corporationId: '',
      corporationName: '',
      corporationShort: '',
      createTime: '',
      creatorEmail: '',
      creatorName: '',
      email: '',
      enabledBusi: 0,
      first: '',
      logo: '',
      phone: '',
      second: '',
      corpAdmin: false,
    },
    apps: [],
    // 用户在当前空间下的管理员情况，当空间切换时同步更新该数据
    orgAdmin: getDefault(),
  }),
  getters: {
    // 是否显示企业设置，这里与版本相关，版本配置优先级最高
    ifShowCorpSettings(state) {
      if (window.AppConfig.navigation.showCorpSettingByAuth === 'busiAdmin') {
        // 是租户管理员 或者 业务管理员时 显示
        return state.corp.corpAdmin || state.orgAdmin.busiAdmin;
      }
      return true;
    },
    // 是否可以创建空间，这里与版本相关，版本配置优先级最高
    ifShowCreateBiz(state) {
      if (window.AppConfig.navigation.enableCreateBizByAuth === 'busiAdmin') {
        // 是租户管理员 或者 业务管理员时 显示
        return state.corp.corpAdmin || state.orgAdmin.busiAdmin;
      }
      return true;
    },
  },
  actions: {
    async fetchUserInfo() {
      const userInfo: UserInfo = await fetchUser();
      Object.assign(this.user, userInfo);
      // RUM 监控设置 uin
      window.aegisSDK?.setConfig({
        uin: userInfo.userId,
        aid: userInfo.userId,
      });
      // TAMV2 uin
      window.aegisV2?.setConfig({
        uid: userInfo.userId,
        aid: userInfo.userId,
      });
      return userInfo;
    },
    /**
     * 设置租户 ID
     * @param corpId
     * @returns
     */
    setCorpId(corpId?: string) {
      const scorpId = String(corpId);
      if (!scorpId) return;
      this.corpId = scorpId;
      if (!this.corp) return;
      // 兼容子应用使用的场景，通常情况下最好使用外层的 corpId
      // 因为租户的详细信息在真正用到的时候才会获取
      this.corp.corporationId = scorpId;
    },
    /**
     * 更新租户信息
     * @param corpInfo
     * @param apps
     */
    updateCorpInfo(corpInfo: CorporationInfo, apps: AppInfo[]) {
      this.corpId = corpInfo.corporationId;
      localStorage.setItem(LS_CORP_ID_KEY, this.corpId);
      merge(this.corp, corpInfo);
      this.apps = apps;
      // 获取租户信息时，清空用户当前空间下的管理员信息
      Object.assign(this.orgAdmin, getDefault());
    },
    /**
     * 初始化租户 ID，只有在用户首次进入页面时路由拦截器内调用触发
     */
    async initCorpId() {
      if (this.corpId) return;
      // 默认租户 ID，内网版本使用 0 表示腾讯租户
      // 其它版本下必须设置为 null 在多租户场景下 null 会获取最近访问的租户
      if (window.AppConfig.defaultCorpId) {
        const { defaultCorpId } = window.AppConfig;
        this.setCorpId(defaultCorpId);
        return;
      }
      const cacheCorpId = localStorage.getItem(LS_CORP_ID_KEY);
      if (cacheCorpId) {
        // 有缓存的情况下不做阻塞
        this.corpId = cacheCorpId;
        fetchCurrentCorpId().then((scopeCorpId) => {
          this.setCorpId(scopeCorpId);
          localStorage.setItem(LS_CORP_ID_KEY, scopeCorpId);
        });
        return;
      }
      // 没缓存的情况下阻塞
      const scopeCorpId = await fetchCurrentCorpId();
      if (!scopeCorpId && window.AppConfig.navigation.showEntryCorpList) {
        // 当前最近访问的租户 ID 为空时，跳转到租户列表页
        // 内网下永远不会走到该逻辑，因为租户只有一个 腾讯集团
        const corpListPath = getCorpListPath();
        const corpListLocation = buildFullPath(corpListPath);
        window.location.href = corpListLocation.href;
        return;
      }
      this.setCorpId(scopeCorpId);
      localStorage.setItem(LS_CORP_ID_KEY, scopeCorpId);
    },
    /**
     * 获取租户信息
     * @param corpId
     * @returns
     */
    async fetchCurrentCorpInfo(corpId: string) {
      // 对于外部子应用传入的 corpId 不可信需要校验转换
      const scopeCorpId = isNil(corpId) ? '' : String(corpId);
      if (!scopeCorpId) {
        console.error('获取更新租户信息失败租户 ID 不存在');
        return;
      }
      const [corpInfo, apps] = await Promise.all([
        fetchCurrentCorpInfo(scopeCorpId),
        fetchAppsByCorpId(scopeCorpId),
      ]);
      this.updateCorpInfo(corpInfo, apps);
    },
    async fetchOrgAdmin(appKey: BEAppKeyType, bizId: string) {
      const orgAdmin = await fetchBizUserAdmin(appKey, bizId);
      Object.assign(this.orgAdmin, orgAdmin);
    },
  },
  // 自定义字段，标识该store是否被全局监听
  globalStore: true,
});
