/**
 * @description 全局弹窗插槽 V2 --- 支持同时打开多弹窗
 * @module types
 */
import type SlotGlobalPopup from 'slot-global-popup';
import type { EventCenterObserverCallback, EventCenterObserverCallbackParams } from '@/services/global-api/index.types';
import { App, createApp } from 'vue';
import { omit } from 'lodash-es';
import { removeDomScope } from '@micro-zoe/micro-app';
import { BaseMessage } from '@suite/shared';
import { handleError, handleGlobalComponents } from '@/entry';
import eventCenter from '@/services/global-api/event/eventCenter';
import { GlobalPopupPluginsStore, getPluginJsonConfig } from '@/services/beacon-plugins';
import GlobalPopupTpl from './main.vue';

/**
 * 创建插槽元素
 * @param id
 * @returns
 */
function createSlotElement(id: string): HTMLDivElement {
  const div = document.createElement('div');
  div.id = `slot-wrap-${id}`;
  return div;
}

/**
 * 获取事件名
 * @param pluginCode
 * @param eventName
 * @returns
 */
function getEventName(pluginCode: string, eventName: string): string {
  return `${pluginCode}:${eventName}`;
}

export class GlobalPopupSlotV2 implements SlotGlobalPopup.IGlobalPopupSlotV2 {
  /**
   * 实例初始化状态
   */
  public initState: Promise<void>;

  /**
   * 全局弹窗插槽实例
   */
  private appInst: App | undefined;

  /**
   * 全局弹窗插槽导出方法
   */
  private get appExposed(): Record<string, any> | null {
    return this.appInst?._instance?.exposed || null;
  }

  /**
   * 插槽父级元素
   */
  private slotParentEl = document.body;

  constructor(
    /**
     * 插件 Code
     */
    public readonly pluginCode: string,
    /**
     * 打开全局弹窗插件传参
     */
    public readonly pluginProps: SlotGlobalPopup.IOpenProps,
  ) {
    this.initState = this.initPlugin();
  }

  /**
   * 打开全局弹窗
   */
  async open(): Promise<void> {
    await this.initState;
    if (!this.appExposed) return;
    if (this.appExposed.visible?.value) return;
    this.appExposed?.open();
  }

  /**
   * 关闭全局弹窗
   */
  close(): void {
    // 清除监听事件
    eventCenter.clearMatch(new RegExp(`^${this.pluginCode}`));
    if (!this.appInst) return;
    // 销毁 dom 节点
    if (this.appInst._container) {
      this.slotParentEl.removeChild(this.appInst._container);
    }
    // 卸载 Vue 实例
    this.appInst.unmount();
    this.appInst = undefined;
  }

  /**
   * 监听插件事件
   * @param eventName
   * @param callback
   */
  $on(eventName: string, callback: EventCenterObserverCallback): void {
    const event = getEventName(this.pluginCode, eventName);
    eventCenter.on(event, callback);
  }

  /**
   * 初始化 Plugin
   */
  private async initPlugin(): Promise<void> {
    console.log('打开全局弹窗插件', this.pluginCode, this.pluginProps);
    try {
      const pluginConfig = await GlobalPopupPluginsStore.find(this.pluginCode);
      if (!pluginConfig) {
        BaseMessage({
          type: 'error',
          message: '全局弹窗配置为空，请稍后重试',
        });
        return;
      }

      const pluginJsonConfig = getPluginJsonConfig<SlotGlobalPopup.IConfig>(pluginConfig);
      // 兼容逻辑
      const customCompProps = omit(pluginJsonConfig?.componentProps, [
        'title',
        'showTitle',
        'size',
      ]);

      if (pluginJsonConfig?.component === 'dialog') {
        Object.assign(pluginJsonConfig, {
          dialogProps: {
            ...customCompProps,
            ...pluginJsonConfig.dialogProps,
          },
        });
      }

      if (pluginJsonConfig?.component === 'drawer') {
        Object.assign(pluginJsonConfig, {
          drawerProps: {
            ...customCompProps,
            ...pluginJsonConfig.drawerProps,
          },
        });
      }
      // 创建全局弹窗元素时，需要解除子应用下的元素绑定，否则会把全局弹窗资源加载到子应用内，导致位置显示异常
      removeDomScope();
      this.appInst = createApp(GlobalPopupTpl, {
        pluginCode: this.pluginCode,
        pluginProps: this.pluginProps,
        pluginConfigs: pluginJsonConfig,
        emits: this.emits.bind(this),
        destroySlot: this.close.bind(this),
      });
      handleGlobalComponents(this.appInst);
      handleError(this.appInst);
      this.appInst.mount(this.slotParentEl.appendChild(createSlotElement(this.pluginCode)));
    } catch (e) {
      console.warn('打开全局弹窗插件失败', e);
      BaseMessage({
        type: 'error',
        message: '全局弹窗加载失败，请稍后重试',
      });
    }
  }

  /**
   * 发出事件
   * @param eventName
   * @param args
   */
  private emits(eventName: string, ...args: EventCenterObserverCallbackParams[]): void {
    const event = getEventName(this.pluginCode, eventName);
    eventCenter.dispatch(event, ...args);
  }
}
