/**
 * @description 全局弹出层插槽模块
 * @module global-api
 */
import type SlotGlobalPopup from 'slot-global-popup';
import type {
  GlobalPopupSlotOpenPayload,
  GlobalPopupSlotClosePayload,
} from '@/components/global-popup-slot/use-global-popup-slot';
import { useGlobalPopupSlot } from '@/components/global-popup-slot/use-global-popup-slot';
import { GlobalPopupSlotV2 } from '@/components/global-popup-slot-v2';
import eventCenter from '../event/eventCenter';
import { EventCenterObserverCallback } from '../index.types';

export interface GlobalPopupSlot {
  open(pluginCode: GlobalPopupPluginCode | string, payload: GlobalPopupSlotOpenPayload): void;
  close(payload: GlobalPopupSlotClosePayload): void;
  setPayload(payload: GlobalPopupSlotOpenPayload): void;
  onOpened(callback: EventCenterObserverCallback): void;
  onClosed(callback: EventCenterObserverCallback): void;
  openCardDetailDrawer(payload: GlobalPopupSlotOpenPayload): void;
  openMarketplaceDialog(payload: GlobalPopupSlotOpenPayload): void;
  openQuotaDialog(): void;
  openV2(pluginCode: string, pluginProps: SlotGlobalPopup.IOpenProps): Promise<GlobalPopupSlotV2 | undefined>;
}

export enum EventType {
  // 弹窗打开事件
  OPENED = 'globalPopupSlot:opened',
  // 弹窗关闭事件
  CLOSED = 'globalPopupSlot:closed',
}

export enum GlobalPopupPluginCode {
  // [Metacat]图卡详情抽屉插件code
  METACAT_CARD_DETAIL = 'GlobalPopup_MetacatCardDetail',
  // mini 云市场弹窗插件 code
  MINI_MARKETPLACE = 'GlobalPopup_Mini_Marketplace',
  // 套餐限额弹窗 code
  SUITE_QUOTA = 'GloabalPopup_Suite_Quota',
}

const globalPopupAPI: GlobalPopupSlot = {
  // 通用打开弹窗
  open(
    pluginCode: GlobalPopupPluginCode,
    payload: GlobalPopupSlotOpenPayload,
  ): void {
    const { open } = useGlobalPopupSlot();
    open({ code: pluginCode }, payload);
  },
  // 关闭弹窗
  close(payload: GlobalPopupSlotClosePayload): void {
    const { close } = useGlobalPopupSlot();
    close(payload);
  },
  // 异步设置payload
  setPayload(payload: GlobalPopupSlotOpenPayload): void {
    const { setPayload } = useGlobalPopupSlot();
    setPayload(payload);
  },
  /**
   * @description 监听弹窗打开完成事件，该事件在弹窗关闭时会被清理
   */
  onOpened(callback: EventCenterObserverCallback): void {
    eventCenter.on(EventType.OPENED, callback);
  },
  /**
   * @description 监听弹窗完成完成事件，该事件在弹窗关闭时会被清理
   */
  onClosed(callback: EventCenterObserverCallback): void {
    eventCenter.on(EventType.CLOSED, callback);
  },
  // 打开Metacat图卡详情页抽屉
  openCardDetailDrawer(payload: GlobalPopupSlotOpenPayload) {
    const { open } = useGlobalPopupSlot();
    open({ code: GlobalPopupPluginCode.METACAT_CARD_DETAIL }, payload);
  },
  // 打开 mini 云市场弹窗
  openMarketplaceDialog(payload: GlobalPopupSlotOpenPayload) {
    const { open } = useGlobalPopupSlot();
    open({ code: GlobalPopupPluginCode.MINI_MARKETPLACE }, payload);
  },
  // 打开套餐限额弹窗
  openQuotaDialog() {
    const { open } = useGlobalPopupSlot();
    open({ code: GlobalPopupPluginCode.SUITE_QUOTA }, {});
  },
  /**
   * 打开全局弹窗，生成全局弹窗插件实例
   * @param pluginCode {string} 插件 code
   * @param pluginProps {Object} 插件所需参数
   */
  async openV2(pluginCode, pluginProps) {
    try {
      const globalPopupInst = new GlobalPopupSlotV2(pluginCode, pluginProps);
      await globalPopupInst.initState;
      globalPopupInst.open();
      return globalPopupInst;
    } catch (error) {
      console.warn('初始化全局弹窗 V2 失败', error);
    }
  },
};

export default globalPopupAPI;
