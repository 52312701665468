import { RightSidebarState, useRightSidebarStore } from '@/stores/right-sidebar';
import { useFeedbackVisible, VisibleName } from '@/components/user-helper/composables/use-visible';
import { IconOption } from '@/stores/right-sidebar/typed';

export interface IRightSideBarAPI {
  getStateVal: <T extends keyof RightSidebarState>(key: T) => RightSidebarState[T];
  toggleDark(isDark: boolean): void;
  toggleSidebar(isOpen: boolean): void;
  setMenu(menu: IconOption[]): void;
  deleteMenu(menuId: string | string[]): void;
  updateMenu(payload: IconOption): void;
  setSidebarStyle(styleConfig: {[key in string]: string}): void;
  setControlStyle(styleConfig: {[key in string]: string}): void;
  updateToggleExpandCallback(cb: (isExpand: boolean) => void | null): void;
  openFeedBack(): void;
  toggleSlideUp(): void;
}

/**
 * 获取 state 的值
 * @param isOpen {boolean}
 */
const getStateVal = function<T extends keyof RightSidebarState>(key: T): RightSidebarState[T] {
  return useRightSidebarStore()[key];
};

/**
 * 切换黑暗模式 适配画布
 * @param isOpen {boolean}
 */
const toggleDark = function toggleDark(isOpen: boolean): void {
  useRightSidebarStore().isDark = isOpen;
};

/**
 * 切换侧边栏
 * @param isOpen {boolean}
 */
const toggleSidebar = function toggleSidebar(isOpen: boolean): void {
  useRightSidebarStore().enableSideBar = isOpen;
};

const openFeedBack = function openFeedBack(): void {
  useRightSidebarStore().enableSideBar = true;
  useFeedbackVisible(VisibleName.rightSideBar).visible.value = true;
};


/**
 * 配置菜单
 * @param options {IconOption}
 */
const setMenu = function setMenu(options: IconOption[]): void {
  useRightSidebarStore().setMenu(options);
};


/**
 * 删除菜单
 * @param menuId {string | string[]}
 */
const deleteMenu = function deleteMenu(menuId: string | string[]): void {
  useRightSidebarStore().deleteMenu(menuId);
};


/**
 * 更新菜单
 * @param options {IconOption}
 */
const updateMenu = function updateMenu(options: IconOption): void {
  useRightSidebarStore().updateMenu(options);
};

/**
 * 设置样式
 * @param styleConfig {[key in string]: string}
 */
const setSidebarStyle = function setStyle(styleConfig: {[key in string]: string}): void {
  useRightSidebarStore().customSidebarStyle = styleConfig;
};

/**
 * 设置按钮样式
 * @param styleConfig {{[key in string]: string}
 */
const setControlStyle = function setStyle(styleConfig: {[key in string]: string}): void {
  useRightSidebarStore().controlBtnStyle = styleConfig;
};

const toggleSlideUp = function toggleSlideUp(showAnimation = true): void {
  useRightSidebarStore().toggleSlideUp(showAnimation);
};

const updateToggleExpandCallback = function updateToggleExpandCallback(callback: (isExpand: boolean) => void | null):
void {
  useRightSidebarStore().toggleExpandCallBack = callback;
};

const rightSideBarAPI: IRightSideBarAPI = {
  getStateVal,
  toggleDark,
  toggleSidebar,
  setMenu,
  deleteMenu,
  updateMenu,
  setSidebarStyle,
  setControlStyle,
  updateToggleExpandCallback,
  toggleSlideUp,
  openFeedBack,
};

export default rightSideBarAPI;
