/**
 * @description 反馈数量气泡
 * @module types
 */

import { Ref, ref } from 'vue';
import { readAllByOwner, countUnreadByOwner } from '@/services/apis/feedback';

const feedbackCount = ref(0);

export function useFeedbackCount(): {
  feedbackCount: Ref<number>;
  loadCount(): Promise<number>;
  setReadAllByOwner(): void;
} {
  const loadCount = (): Promise<number> => countUnreadByOwner().then((res: number) => {
    feedbackCount.value = res;
    return res;
  });

  const setReadAllByOwner = (): void  => {
    readAllByOwner().then(() => {
      feedbackCount.value = 0;
    });
  };

  return {
    feedbackCount,
    loadCount,
    setReadAllByOwner,
  };
}
