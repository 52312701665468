// 小助手
import { UserHelper } from '@suite/shared';
import type { PositionStyleConfig } from '@suite/shared';

// 查询状态
export enum QueryStatusEnum {
  /** 初始化中 */
  INITIALIZING = 'initializing',
  /** 过期任务计时中 */
  EXPIREDTASKTIMER = 'expiredTaskTimer',
  /** 查询提交中 */
  QUERY_SUBMIT = 'SUBMIT',
  /** 查询排队中 */
  QUERY_QUEUED = 'QUEUED',
  /** 查询运行中 */
  QUERY_RUNNING = 'RUNNING',
  /** 查询成功 */
  QUERY_SUCCESS = 'SUCCESS',
  /** 查询失败 */
  QUERY_FAIL = 'FAIL',
  /** 查询取消 */
  QUERY_KILLED = 'KILLED',
  /** 诊断初始化 */
  DIAGNOSING_INIT= 'DIAGNOSING_INIT',
  /** 诊断中 */
  DIAGNOSING= 'DIAGNOSING',
  /** 诊断完成 */
  DIAGNOSED= 'DIAGNOSED',
  /** 诊断失败 */
  DIAGNOSIS_FAILED='DIAGNOSIS_FAILED'
}

export interface HelperAPI {
  // 初始化
  init: () => void;
  // 销毁
  destory: () => void;
  onClickHelper: () => void;
  // 校验白名单
  checkWhitelist: (bizId: string) => Promise<boolean>;
  // 切换小助手是否显示
  toggleUserHelper: (enabled: boolean) => void,
  // 添加任务
  addTask: (traceId: string, datasourceId: number, delayed: number) => void,
  // 更新任务状态
  updateTask: (traceId: string, status: QueryStatusEnum) => void,
  // 重置为初始状态
  reset: () => void;
  setPositionStyle: (styleConfig: PositionStyleConfig) => void,
}

const helperAPI: HelperAPI = {
  init() {
    UserHelper.init();
  },
  destory() {
    UserHelper.destory();
  },
  onClickHelper() {
    UserHelper.onClickHelper();
  },
  async checkWhitelist(bizId: string) {
    return await UserHelper.checkInWhitelist(bizId);
  },
  toggleUserHelper(enabled: boolean) {
    UserHelper.helperStore?.toggleUserHelper(enabled);
  },
  addTask(traceId: string, datasourceId: number, delayed: number) {
    UserHelper.httpQueusStore?.addHttpTask(traceId, datasourceId, delayed);
  },
  updateTask(traceId: string, status: QueryStatusEnum) {
    UserHelper.httpQueusStore?.setHttpTaskStatus(traceId, status);
  },
  reset() {
    UserHelper.httpQueusStore?.resetHttpTasks();
    UserHelper.helperStore?.closeAllDialog();
  },
  setPositionStyle(styleConfig: PositionStyleConfig) {
    UserHelper.helperStore?.setPositionStyle(styleConfig);
  },
};


export default helperAPI;
