/**
 * @description 全局通信 API
 * @module global-api
 */
import extendedAPI from '@version-inject/services/global-api';
import { injectJS } from '@/entry';
import type { GlobalAPI } from './index.types';
import user from './modules/user';
import router from './modules/router';
import navigation from './modules/navigation';
import utils from './modules/utils';
import micro from './modules/micro-app';
import auth from './modules/authorize';
import performance from './modules/performance';
import queryPerformanceHelper from './modules/query-performance-helper';
import globalPopupSlot from './modules/global-popup-slot';
import globalToolkit from './modules/global-toolkit';
import userHelper from './modules/user-helper';
import rightSideBar from './modules/right-sidebar';
import olaChat from './modules/ola-chat';
import { patchGlobalAPI } from './utils/patch-global-api';

// 合并所有模块方法
const globalAPI: GlobalAPI = patchGlobalAPI({
  user,
  router,
  navigation,
  utils,
  micro,
  auth,
  performance,
  globalPopupSlot,
  globalToolkit,
  queryPerformanceHelper,
  userHelper,
  rightSideBar,
  olaChat,
}, extendedAPI);

injectJS().then((res) => {
  if (!res) return;
  // 通过动态扩展注入
  res.patchGlobalAPI(globalAPI);
});

if (process.env.VUE_APP_NODE_ENV !== 'production') {
  // 非正式环境便于外部调试
  window.GlobalDebugUtils = window.GlobalDebugUtils || {};
  Object.assign(window.GlobalDebugUtils, {
    globalAPI,
  });
}

export default globalAPI;
