/**
 * @Author xqbzheng xqbzheng@tencent.com
 * @Date 2023-11-14 17:50:20
 * @LastEditors xqbzheng
 * @LastEditTime 2023-11-15 11:11:10
 * @description
 * @
 * @module types
 */
import { ref, Ref } from 'vue';
// 分顶部右上角+侧边栏右下角两个反馈入口
const topNavVisible = ref(false);
const rightSideBarVisible = ref(false);
const singleGlobalIconVisible = ref(false);
export enum VisibleName  {
  // 顶部导航栏
  topNav = 'topNav',
  // 侧边工具栏
  rightSideBar = 'rightSideBar',
  // 独立的全局icon，没有侧边栏的情况
  singleGlobalIcon = 'singleGlobalIcon',
}

interface IUseFeedbackVisible {
  visible: Ref<boolean>
}
export const useFeedbackVisible = (visibleNameKey: VisibleName): IUseFeedbackVisible => ({
  visible: {
    [VisibleName.topNav]: topNavVisible,
    [VisibleName.rightSideBar]: rightSideBarVisible,
    [VisibleName.singleGlobalIcon]: singleGlobalIconVisible,
  }[visibleNameKey],
});
