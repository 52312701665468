/**
 * @description
 * @module types
 */
import globalAPI from '@/services/global-api';
import { useMicroAppStore } from '../micro-app';

export const DEFAULT_GUIDES = [
  {
    id: 'wiki',
    icon: 'doubt-filled',
    tooltipContent: '帮助文档',
    onClick: () => ({}),
  },
  {
    id: 'feedback',
    icon: 'helper-filled',
    tooltipContent: '使用反馈',
    onClick: () => ({}),
  },
  {
    id: 'auth',
    icon: 'authority-filled',
    tooltipContent: '权限中心',
    onClick: () => {
      const microAppStore = useMicroAppStore();
      globalAPI.auth.getAuthSettingsPath({
        open: true,
        appKey: microAppStore.currentAppIdBE,
      });
    },
  },
];
