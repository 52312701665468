/**
 * @description 上报pv相关
 * @module entry
 */
import beaconSdk from '@/utils/sdk-report';
import { HISTORY_URL_CHANGE_EVENT_KEY } from './patch-route';
import { debounce } from 'lodash-es';

// 由于部分页面落地是会出现replace多次情况，导致重复上报，所以这里加了个防抖
const reportPv = debounce(() => beaconSdk.onDirectUserAction('page_visit', {}), 500);

export const subscriptionPvReport = (): void => {
  document.addEventListener(HISTORY_URL_CHANGE_EVENT_KEY, reportPv);
};

